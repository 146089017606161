import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button } from "reactstrap";
import { Grid } from "rsuite";
import ShowLoader from "src/components/CustomComponents/ShowLoader";
import TableCells from "src/components/Tables/TableCells";
import SelectInputs from "src/components/inputs/SelectInputs";
import TextInputs from "src/components/inputs/TextInputs";
import { useMain } from "src/views/hooks/useMain";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { QurealCollection, useQurealActions } from "qureal-editor";

const initialValue = {
    packageName: '',
    price: '',
    packageDuration: '',
};
const AllPackages = ({ data, setPackageData, getPackageData }) => {

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [openEyeDialog, setOpenEyeDialog] = useState(false);
    const [selectedCardItem, setSelectedCardItem] = useState({});
    const [updateFormData, setUpdateFormData] = useState(initialValue);
    const [openDialogue, setOpenDialogue] = useState(false);
    const [loading, setLoading] = useState(false);


    const { actionRef, download, share } = useQurealActions()

    const { activeBranchId, updatePackage, setShowMessage } =
        useMain();

    const navigate = useNavigate()
    const { state } = useLocation()

    const handleCloseDialogue = () => {
        setOpenDialogue(false);
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setUpdateFormData((pre) => ({ ...pre, [name]: value }));
    // };
    const handleEyeClick = (item) => {
        console.log(item, 'itemmmmmmm')
        setSelectedCardItem(item);
        setOpenEyeDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenEyeDialog(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Convert packageDuration to a number if it's the packageDuration field
        if (name === 'packageDuration') {
            setUpdateFormData((prev) => ({ ...prev, [name]: Number(value) }));
        } else {
            setUpdateFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleUpdate = (item) => {
        setSelectedItemId(item._id);
        let { packageName, price, packageDuration } = item;

        setUpdateFormData((pre) => ({
            ...pre,
            packageName,
            price,
            packageDuration,
        }));
        setOpenDialogue(true);
    };

    const hadleUpdatePackage = async () => {
        try {
            setLoading(true);
            const res = await updatePackage(
                selectedItemId,
                updateFormData,
                `?branchId=${activeBranchId}`,
            );
            if (res.statusCode === 200) {
                setShowMessage({
                    message: res.message || 'Update Package successfully',
                    messageType: 'success',
                });
                setPackageData([]);
                getPackageData();
                setUpdateFormData((pre) => ({
                    ...pre,
                    ...initialValue,
                }));
            } else {
                setShowMessage({
                    message: res.message || 'Update Package failed',
                    messageType: 'error',
                });
            }
        } catch (error) {
            setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
        } finally {
            setLoading(false);
            setOpenDialogue(false);
        }
    };

    const handleDetails = (id) => {
        navigate(`/packagedetail/${id}`, { state });
    };

    const buildContent = (services) => {
        console.log(services, 'servicessss')
        return services?.map(serviceObj => {
            return `✶ ${serviceObj?.serviceName}`;
        }).join('<br>');
    }
   
    const calculateTotalPrice = (services) => {
        return services?.reduce((total, serviceObj) => {
            return total + serviceObj.price;  // Add the price of each service to the total
        }, 0);  // Start with an initial value of 0
    };

    // const buildPriceComparison = (selectedCardItem) => {
    //     // Get the total price of the services
    //     const totalServicePrice = calculateTotalPrice(selectedCardItem?.services);
    //     console.log(totalServicePrice,'totalServicePrice')
    //     // Compare the total service price with package price
    //     const packagePrice = selectedCardItem?.price || 0;
    //      console.log(packagePrice,'packagePrice')
    //     if (totalServicePrice > packagePrice) {
    //         // If the total price of services is less than the package price, show the discount
    //         return `
    //             <span style="text-decoration: line-through; color: black;">
    //                 Rs ${totalServicePrice} 
    //             </span>
    //             <span style="font-weight: bold; color: green;">
    //                 Rs ${packagePrice}
    //             </span>
    //         `;
    //     } else {
    //         // If the total price of services is the same or greater than the package price, just show the package price
    //         return `Rs ${packagePrice}`;
    //     }
    // };

    const buildPriceComparison = (selectedCardItem) => {
        // Get the total price of the services
        const totalServicePrice = calculateTotalPrice(selectedCardItem?.services);
        console.log(totalServicePrice, 'totalServicePrice');
        
        // Compare the total service price with package price
        const packagePrice = selectedCardItem?.price || 0;
        console.log(packagePrice, 'packagePrice');
    
        if (totalServicePrice > packagePrice) {
            // Calculate percentage of savings
            const discountPercentage = Math.round(((totalServicePrice - packagePrice) / totalServicePrice) * 100);
    
            // If the total price of services is greater than the package price, show the discount message
            return `
                <span style="text-decoration: line-through; color: black;">
                    Rs ${totalServicePrice} 
                </span>
                <span style="font-weight: bold; color: green;">
                    Rs ${packagePrice}
                </span>
                <span style="color: blue; font-size: 14px; margin-left: 5px;">
                    (Save ${discountPercentage}% Amount on this package!)
                </span>
            `;
        } else {
            // If no savings, just show the package price
            return `Rs ${packagePrice}`;
        }
    };
    
    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
            <table className="table-container mt-3">
                <thead className="table-thead">
                    <tr>
                        {[
                            'Package Name',
                            'Package Price',
                            'Package Duration(in Months)',
                            'Actions',
                        ].map((h, index) => {
                            return (
                                <th key={index} style={{ width: '100%' }}>
                                    {h}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="table-tbody">
                    {data.map((item) => {
                        const originalDate = item?.validTill;

                        // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
                        let formattedValidityDate = '';
                        if (originalDate) {
                            const [year, month, day] = originalDate.split('-');
                            formattedValidityDate = `${day}-${month}-${year}`;
                        }
                        return (
                            <tr key={item._id}>
                                <td style={{ width: '100%' }}>{item.packageName}</td>
                                <td style={{ width: '100%' }}>{item.price}</td>
                                <td style={{ width: '100%' }}>
                                    {item.packageDuration ? item.packageDuration : formattedValidityDate}
                                </td>

                                {/* <td style={{ width: '100%' }}>
                                    <TableCells
                                        type="button"
                                        handleDeleteField={""}
                                        handleDetails={handleDetails}
                                        handleUpdate={() => handleUpdate(item)}
                                        field={item?._id}
                                    />
                                </td> */}
                                <td style={{
                                    width: '116%'
                                }}>
                                    <IconButton onClick={() => handleDetails(item._id)} color="primary" title="View Details" style={{ marginRight: '-5px' }}>
                                        <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem', marginLeft: '1.5rem' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                                        <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleEyeClick(item)} color="primary" title="View">
                                        <FaEye color="black" style={{ fontSize: '1.2rem', marginTop: '1px', marginLeft: '20px' }} />
                                    </IconButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* {Card-Membership} */}
            <Dialog
                open={openEyeDialog}
                onClose={handleCloseDialog}
                maxWidth={false} // Disables default maxWidth handling
                PaperProps={{
                    style: {
                        width: "1100px", // Fixed width of the dialog
                        height: "100vh", // Set height to 100vh
                        margin: "auto", // Centers the dialog horizontally
                        display: "flex", // Ensures the dialog content takes full height
                        flexDirection: "column", // Aligns content in a column
                        borderRadius: "10px", // Border radius for smooth corners
                        padding: "10px", // Padding around the dialog (optional)
                    },
                }}
            >
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1, // Ensures content fills the available space
                        padding: "0", // No padding inside content
                        backgroundColor: "white", // White background inside the content
                        overflow: "hidden", // Prevents vertical scrollbars
                    }}
                >
                    {/* QurealCollection component */}
                    <QurealCollection
                        collection_id="9ff9f448-3ce3-4d09-9bcc-4e5c4d763a9a"
                        organization="your_salon_QBdXA"
                        background="white" // White background for the collection
                        style={{
                            width: "100%", // Ensures the component fills the full width
                            height: "100%", // Ensures the component fills the full height
                            border: "1px solid black", // Border around the component
                            borderRadius: "10px", // Rounded corners
                            overflow: "hidden", // Prevents scrollbars inside the component
                        }}
                        actionRef={actionRef}
                        preview={true}
                        prefill={{
                            discount_offer_with_body: {
                                offer_name: selectedCardItem.packageName,
                                offer_price: `RS ${selectedCardItem.price}`,
                                validity: selectedCardItem?.validTill
                                ? `Valid for ${new Date(selectedCardItem.validTill).toLocaleDateString()}`
                                : `Valid for ${selectedCardItem?.packageDuration || 0} Months`,
                              
                                content: buildContent(selectedCardItem?.services),
                                extra: `${buildPriceComparison(selectedCardItem)}`
                            },
                            ai_model: {
                                model: "e9c8b5c4-4a2f-4c15-85a4-a57669a00999",
                            },
                        }}
                    />
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "flex-end", // Align buttons to the right
                        padding: "16px", // Padding for buttons
                        backgroundColor: "#f5f5f5", // Optional: light grey background for the button section
                    }}
                >
                    <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        style={{
                            marginRight: "10px", // Space between buttons
                        }}
                    >
                        Close
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            background: '#6174DD',
                            marginRight: "10px", // Space between buttons
                        }}
                        onClick={share}
                    >
                        Share
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            background: '#6174DD',
                        }}
                        onClick={download}
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
                <DialogTitle> Package Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>Here you can update Package details.</DialogContentText>
                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Package Name'}
                                    name={'packageName'}
                                    value={updateFormData?.packageName}
                                    handleChange={handleInputChange}
                                    type={'text'}
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Package Price'}
                                    name={'price'}
                                    value={updateFormData?.price}
                                    handleChange={handleInputChange}
                                    type={'number'}
                                    required={false}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextInputs
                                    title={'Duration (in Months)'}
                                    name={'packageDuration'}
                                    value={updateFormData?.packageDuration}
                                    handleChange={handleInputChange}
                                    type={'number'}
                                    min={new Date().toISOString().split('T')[0]}
                                // fieldValidation={validTillValidation && formValidation.validTillValidation}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <ShowLoader />
                    ) : (
                        <>
                            {/* <div style={{ width: '200px', margin: '3rem auto' }}> */}
                            <div style={{ margin: '3rem auto' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    target="_blank"
                                    sx={{
                                        background: '#6174DD',
                                    }}
                                    onClick={handleCloseDialogue}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div style={{ margin: '3rem auto' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    target="_blank"
                                    sx={{
                                        background: '#6174DD',
                                    }}
                                    onClick={hadleUpdatePackage}
                                >
                                    update
                                </Button>
                            </div>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default AllPackages
