import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { longFormatters } from 'date-fns';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { QurealCollection, useQurealActions } from "qureal-editor";

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  // membershipType: 'discountType',
  discount: {
    discountOnService: '',
    serviceDiscountType: '%',
    discountOnProduct: '',
    productDiscountType: '%',
    // discountOnPackages: '',
    // packageDiscountType: '%',
    minBillAmount: '',
  },
};

const DiscountType = ['%', 'INR'];

const DiscountTypeFieldData = ({ data, setData, getData, state }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openEyeDialog, setOpenEyeDialog] = useState(false); // For the "eye" dialog
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCardItem, setSelectedCardItem] = useState({});
  const [updateFormData, setUpdateFormData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { actionRef, download, share } = useQurealActions()
  const navigate = useNavigate();

  const { deleteMembership, updateMembership, setShowMessage, activeBranchId } = useMain();

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleDetails = (id) => {
    navigate(`/membershipdetail/${id}`, { state });
  };

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    const { membershipName, membershipPrice, duration, rewardPointsOnPurchase, discount } = item;
    setUpdateFormData({
      ...initialValue,
      membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      discount: {
        ...initialValue.discount,
        ...discount,
      },
    });
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, discount: { ...prev.discount, [name]: value }, }));
  };

  const handleUpdateDiscountTypeMembership = async () => {
    try {
      setLoading(true);
      const formData = {
        ...updateFormData,
        discount: {
          ...updateFormData.discount,
          discountOnService: updateFormData.discount.discountOnService || '',
          discountOnProduct: updateFormData.discount.discountOnProduct || '',
          // discountOnPackages: updateFormData.discount.discountOnPackages || '',
          minBillAmount: updateFormData.discount.minBillAmount || '',
        },
      };
      const res = await updateMembership(selectedItemId, formData, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Membership Details updated successfully',
          messageType: 'success',
        });
        getData();
        setUpdateFormData(initialValue);
      } else {
        setShowMessage({
          message: res.message || 'Membership Details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteMembership(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setData(data?.filter((item) => item?._id !== id));
  //       setShowMessage({
  //         message: res.message || 'Membership deleted successfully',
  //         messageType: 'success',
  //       });
  //     } else {
  //       setShowMessage({ message: res.message || 'Membership not deleted', messageType: 'error' });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   } finally {
  //     setOpenDialogue(false);
  //   }
  // };
  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  const handleEyeClick = (item) => {
    console.log(item, 'itemmmmmmm')
    setSelectedCardItem(item);
    setOpenEyeDialog(true);

  };

  const handleCloseDialog = () => {
    setOpenEyeDialog(false);
  };

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deleteMembership(deleteId, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Membership deleted successfully',
          messageType: 'success',
        });
        setData(data?.filter((d) => d?._id !== deleteId));
        await getData(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Membership not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleDiscountTypeChange = (event, newDiscountType, type) => {
    if (newDiscountType !== null) {
      setUpdateFormData((prev) => ({
        ...prev,
        discount: {
          ...prev.discount,
          [type]: newDiscountType,
        },
      }));
    }
  };
  console.log(data, "data")

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Membership Name',
              'Membership Price',
              'Validity',
              'Discount on Services',
              'Discount on Products',
              // 'Discount on Packages',
              'Min Billed Amount',
              'Rewards Points',
              'Actions',
            ].map((h, index) => (
              <th key={index} style={{ width: '15%' }}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => (
            <tr key={item._id}>
              <td style={{ width: '100%' }}>{TrimData(item?.membershipName)}</td>
              <td style={{ width: '100%' }}>{item?.membershipPrice}</td>
              <td style={{ width: '100%' }}>{item?.duration}</td>
              <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnService} ${item?.discount?.serviceDiscountType}`}
              </td>
              <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnProduct} ${item?.discount?.productDiscountType}`}
              </td>
              {/* <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnPackages} ${item?.discount?.packageDiscountType}`}
              </td> */}
              <td style={{ width: '100%' }}>{item?.discount?.minBillAmount}</td>
              <td style={{ width: '100%' }}>{item?.rewardPointsOnPurchase}</td>
              {/* <td style={{ width: '100%' }}>
                <TableCells
                  type="button"
                  handleDeleteField={handleDeleteField}
                  handleUpdate={() => handleUpdate(item)}
                  handleDetails={handleDetails}
                  field={item?._id}
                />
              </td> */}
              <td style={{
                width: '100%',
                marginLeft: '-3.2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '3rem'
              }}>
                <IconButton onClick={() => handleDetails(item._id)} color="primary" title="View Details" style={{ marginRight: '-5px' }}>
                  <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem', marginLeft: '1.5rem' }} />
                </IconButton>
                <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                  <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                </IconButton>
                <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ marginLeft: '-33px' }}>
                  <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                </IconButton>
                <IconButton onClick={() => handleEyeClick(item)} color="primary" title="View">
                  <FaEye color="black" style={{ fontSize: '1.2rem', marginTop: '1px', marginLeft: '20px' }} />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Dialog for "Eye" click */}
      {/* <Dialog
        open={openEyeDialog}
        onClose={handleCloseDialog}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "1100px",
            height: "100vh", 
          },
        }}
      >
        <DialogContent>
        
          <QurealCollection
            collection_id="5cc9b8dc-f6d5-49fa-8fc8-658d9b3a4165"
            organization="your_salon_QBdXA"
            background="white"
            style={{
              width: "1100px",
              height: "100vh",
              border: "1px solid black",
              borderRadius: "10px",
            }}
            actionRef={actionRef}
            preview={true}
            prefill={{
              discount_offer: {
                offer_name: selectedCardItem?.membershipName,
                discount_amount: `${selectedCardItem?.discount?.discountOnService}% `,
                discount_on: "discount on all services ",
                offer_price: `${selectedCardItem?.membershipPrice}`,
                validity: `valid for ${selectedCardItem?.duration} months`,
                condition: `only applicable on minimum bill amount Rs${selectedCardItem?.discount?.minBillAmount}`
              },
              ai_model: {
                model: "e9c8b5c4-4a2f-4c15-85a4-a57669a00999"
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              background: '#6174DD',
            }}
            onClick={share}>
            share
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              background: '#6174DD',
            }}
            onClick={download}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={openEyeDialog}
        onClose={handleCloseDialog}
        maxWidth={false} // Disables default maxWidth handling
        PaperProps={{
          style: {
            width: "1100px", // Fixed width of the dialog
            height: "100vh", // Set height to 100vh
            margin: "auto", // Centers the dialog horizontally
            display: "flex", // Ensures the dialog content takes full height
            flexDirection: "column", // Aligns content in a column
            borderRadius: "10px", // Border radius for smooth corners
            padding: "10px", // Padding around the dialog (optional)
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1, // Ensures content fills the available space
            padding: "0", // No padding inside content
            backgroundColor: "white", // White background inside the content
            overflow: "hidden", // Prevents vertical scrollbars
          }}
        >
          {/* QurealCollection component */}
          <QurealCollection
            collection_id="5cc9b8dc-f6d5-49fa-8fc8-658d9b3a4165"
            organization="your_salon_QBdXA"
            background="white" // White background for the collection
            style={{
              width: "100%", // Ensures the component fills the full width
              height: "100%", // Ensures the component fills the full height
              border: "1px solid black", // Border around the component
              borderRadius: "10px", // Rounded corners
              overflow: "hidden", // Prevents scrollbars inside the component
            }}
            actionRef={actionRef}
            preview={true}
            prefill={{
              discount_offer: {
                offer_name: selectedCardItem?.membershipName,
                discount_amount: `${selectedCardItem?.discount?.serviceDiscountType === "%" 
                  ? `${selectedCardItem?.discount?.discountOnService}%` 
                  : `Rs${selectedCardItem?.discount?.discountOnService}`
                }`,
                discount_on: `${selectedCardItem?.discount?.serviceDiscountType === "%" 
                  ? `discount on all Services`
                  : `discount on all Services`
                }, and ${
                  selectedCardItem?.discount?.productDiscountType === "%" 
                  ? `${selectedCardItem?.discount?.discountOnProduct}% discount on all Products`
                  : `Rs${selectedCardItem?.discount?.discountOnProduct} discount on all Products`
                }`,

                offer_price: `${selectedCardItem?.membershipPrice}`,
                validity: `valid for ${selectedCardItem?.duration} months`,
                condition: `only applicable on minimum bill amount Rs${selectedCardItem?.discount?.minBillAmount}`,
              },
              ai_model: {
                model: "e9c8b5c4-4a2f-4c15-85a4-a57669a00999",
              },
            }}
          />
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-end", // Align buttons to the right
            padding: "16px", // Padding for buttons
            backgroundColor: "#f5f5f5", // Optional: light grey background for the button section
          }}
        >
          <Button
            onClick={handleCloseDialog}
            color="primary"
            style={{
              marginRight: "10px", // Space between buttons
            }}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              background: '#6174DD',
              marginRight: "10px", // Space between buttons
            }}
            onClick={share}
          >
            Share
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              background: '#6174DD',
            }}
            onClick={download}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this Membership?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* DiscountType membership details dialog */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>DiscountType Membership Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update membership details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Membership Name'}
                  name={'membershipName'}
                  value={updateFormData?.membershipName}
                  handleChange={handleChange}
                  type={'text'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Duration (in Months)'}
                  name={'duration'}
                  min={'0'}
                  // placeholder={'0'}
                  value={updateFormData?.duration}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Membership Price'}
                  name={'membershipPrice'}
                  min={'1'}
                  value={updateFormData?.membershipPrice}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title={'Discount Service'}
                      name={'discountOnService'}
                      value={updateFormData?.discount?.discountOnService}
                      handleChange={handleDiscountChange}
                      type={'number'}
                      min={1}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '30px', fontWeight: 'bold', color: 'black' }}>Discount Type</div>
                    {/* <Select
                      name="serviceDiscountType"
                      value={updateFormData?.discount?.serviceDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <ToggleButtonGroup
                      value={updateFormData?.discount?.serviceDiscountType}
                      exclusive
                      onChange={(event, newDiscountType) =>
                        handleDiscountTypeChange(event, newDiscountType, 'serviceDiscountType')
                      }
                      aria-label="service discount type"
                      fullWidth
                    >
                      <ToggleButton value="%" aria-label="percentage" sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        // borderRadius:'50%',
                        height: '3.3rem',
                        width: '4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}>
                        %
                      </ToggleButton>
                      <ToggleButton value="INR" aria-label="currency" sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        // borderRadius:'50%',

                        height: '3.3rem',
                        width: '4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}>
                        INR
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title={'Discount Product'}
                      name={'discountOnProduct'}
                      value={updateFormData?.discount?.discountOnProduct}
                      handleChange={handleDiscountChange}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '30px', fontWeight: 'bold', color: 'black' }}>Discount Type</div>
                    {/* <Select
                      name="productDiscountType"
                      value={updateFormData?.discount?.productDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <ToggleButtonGroup
                      value={updateFormData?.discount?.productDiscountType}
                      exclusive
                      onChange={(event, newDiscountType) =>
                        handleDiscountTypeChange(event, newDiscountType, 'productDiscountType')
                      }
                      aria-label="product discount type"
                      fullWidth
                    >
                      <ToggleButton value="%" aria-label="percentage" sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        // borderRadius:'50%',

                        height: '3.3rem',
                        width: '4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}>
                        %
                      </ToggleButton>
                      <ToggleButton value="INR" aria-label="currency" sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        // borderRadius:'50%',

                        height: '3.3rem',
                        width: '4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}>
                        INR
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Reward Points'}
                  name={'rewardPointsOnPurchase'}
                  value={updateFormData?.rewardPointsOnPurchase}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Minimum Bill Amount'}
                  name={'minBillAmount'}
                  value={updateFormData?.discount?.minBillAmount}
                  handleChange={handleDiscountChange}
                  type={'number'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateDiscountTypeMembership}
                >
                  Update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DiscountTypeFieldData;
