// import React, { useEffect } from 'react';
// import { Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
// import {
//   CalendarMonthOutlined,
//   EastRounded,
//   GraphicEqOutlined,
//   PersonAddAlt1Outlined,
//   SentimentVerySatisfiedRounded,
// } from '@mui/icons-material';
// import { Link } from 'react-router-dom';
// import CustomShowLoader from 'src/components/CustomComponents/CustomShowLoader';

// const Overview = ({overviewData}) => {
  


//   const data = [
//     {
//       no: overviewData?.monthlyAttendance,
//       label: 'MONTHLY PRESENT',
//       color: '#FF5E1B',
//       icon: <GraphicEqOutlined sx={{ color: '#FF5E1B' }} />,
//       title: 'Monthly Present',
//     },
//     {
//       no: overviewData?.monthlyAppointments,
//       label: 'TODAY APPOINTMENTS',
//       color: '#03C4A9',
//       icon: <CalendarMonthOutlined sx={{ color: '#03C4A9' }} />,
//       title: 'Appointments',
//     },
//     {
//       no: overviewData?.totalSalary,
//       label: 'TOTAL SALARY',
//       color: '#3071FE',
//       icon: <PersonAddAlt1Outlined sx={{ color: '#3071FE' }} />,
//       title: 'Salary',
//     },
//     {
//       no: overviewData?.totalIncentive,
//       label: 'TOTAL INCENTIVE',
//       color: '#FBCA0E',
//       icon: <SentimentVerySatisfiedRounded sx={{ color: '#FBCA0E' }} />,
//       title: 'Incentive',
//     },
//   ];
//   //console.log(monthlyAttendanceData);
//   return (
//     <Grid container spacing={6}>
//       {data.map((item, index) => (
//         <Grid item xs={6} md={3} key={index}>
//           <Paper
//             elevation={3}
//             style={{
//               backgroundColor: item.color,
//               textAlign: 'center',
//               height: '120px',
//               padding: '20px 20px',
//               display: 'flex',
//               alignItems: 'start',
//               justifyContent: 'space-between',
//               flexDirection: 'column',
//               borderRadius: '10px',
//               gap: '10px',
//             }}
//           >
//             <Stack
//               direction="row"
//               spacing={2}
//               width={'100%'}
//               color={'white'}
//               alignItems={'flex-start'}
//               justifyContent={'space-between'}
//             >
//               <IconButton
//                 sx={{
//                   background: 'white',
//                   '&:hover': {
//                     background: 'white',
//                   },
//                 }}
//               >
//                 {item.icon}
//               </IconButton>
//             </Stack>
//             <div style={{ display: 'flex', gap: '10px' }}>
//               <div style={{ color: '#fff', fontWeight: '900', fontSize: '25px' }}>
//                 {item.no}
//               </div>
//               <div style={{ color: '#fff', fontWeight: '600' }}>{item.label}</div>
//             </div>
//           </Paper>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default Overview;

import React from 'react';
import { Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import {
  CalendarMonthOutlined,
  EastRounded,
  GraphicEqOutlined,
  PersonAddAlt1Outlined,
  SentimentVerySatisfiedRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook

const Overview = ({ overviewData,selectedStaffId }) => {
  const navigate = useNavigate(); // Initialize navigate hook

  const data = [
    {
      no: overviewData?.monthlyAttendance,
      label1: 'MONTHLY PRESENT',
      // label2: 'MONTHLY WEEK OFF',
      no1: overviewData?.monthlyAttendance,
      // no2: overviewData?.monthlyWeekOffs,
      color: '#FF5E1B',
      icon: <GraphicEqOutlined sx={{ color: '#FF5E1B' }} />,
      title: 'Attendance & Week Off',
    },
    {
      no: overviewData?.monthlyAppointments,
      label: 'TODAY APPOINTMENTS',
      color: '#03C4A9',
      icon: <CalendarMonthOutlined sx={{ color: '#03C4A9' }} />,
      title: 'Appointments',
    },
    {
      no: overviewData?.totalSalary,
      label: 'TOTAL SALARY',
      color: '#3071FE',
      icon: <PersonAddAlt1Outlined sx={{ color: '#3071FE' }} />,
      title: 'Salary',
      onClick: () => navigate(`/salary-page?staffId=${selectedStaffId}`)
    },
    {
      no: overviewData?.totalIncentive,
      label: 'TOTAL INCENTIVE',
      color: '#FBCA0E',
      icon: <SentimentVerySatisfiedRounded sx={{ color: '#FBCA0E' }} />,
      title: 'Incentive',
    },
  ];
console.log(overviewData,'overviewData')
  return (
    <Grid container spacing={6}>
      {data.map((item, index) => (
        <Grid item xs={6} md={3} key={index}>
          <Paper
            elevation={3}
            style={{
              backgroundColor: item.color,
              textAlign: 'center',
              height: '120px',
              padding: '20px 20px',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              flexDirection: 'column',
              borderRadius: '10px',
              gap: '10px',
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              width={'100%'}
              color={'white'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <IconButton
                sx={{
                  background: 'white',
                  '&:hover': {
                    background: 'white',
                  },
                }}
              >
                {item.icon}
              </IconButton>

              {/* Add Arrow Icon with OnClick */}
              {index === 2 && ( // Only add the arrow icon for the TOTAL SALARY card
                <IconButton
                  sx={{
                    color: 'white',
                    '&:hover': {
                      color: `${item.color}`,
                      background: 'white',
                    },
                  }}
                  onClick={item.onClick} // Redirect to the new page
                >
                  <EastRounded />
                </IconButton>
              )}
            </Stack>

            {/* Displaying the first block with two labels */}
            {index === 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', color: '#fff' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                  <div style={{ color: '#fff', fontWeight: '900', fontSize: '20px' }}>
                    {item.no1}
                  </div>
                  <div style={{ color: '#fff', fontWeight: '600' }}>{item.label1}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                  <div style={{ color: '#fff', fontWeight: '900', fontSize: '20px' }}>
                    {item.no2}
                  </div>
                  <div style={{ color: '#fff', fontWeight: '600' }}>{item.label2}</div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ color: '#fff', fontWeight: '900', fontSize: '25px' }}>
                  {item.no}
                </div>
                <div style={{ color: '#fff', fontWeight: '600' }}>{item.label}</div>
              </div>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Overview;

