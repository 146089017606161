
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button, Box } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import PageContainer from 'src/components/container/PageContainer';
import { useMain } from '../hooks/useMain';
import "./PaySlip.css"

// Function to convert numbers to words (supports up to 99999)
const convertNumberToWords = (num) => {
    if (num === 0) return 'zero';

    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const thousands = ['thousand'];

    let words = '';
    if (num >= 1000) {
        words += ones[Math.floor(num / 1000)] + ' ' + thousands[0] + ' ';
        num %= 1000;
    }
    if (num >= 100) {
        words += ones[Math.floor(num / 100)] + ' hundred ';
        num %= 100;
    }
    if (num > 10 && num < 20) {
        words += teens[num - 11] + ' ';
        num = 0;
    } else if (num >= 10) {
        words += tens[Math.floor(num / 10)] + ' ';
        num %= 10;
    }
    if (num > 0) {
        words += ones[num] + ' ';
    }
    return words.trim();
};

const SalarySlipPdf = () => {
    const componentRef = useRef();
    const location = useLocation();
    const { state } = location;
    const { staff, salarySlip } = state || {};
    const { branchData } = useMain();
    // console.log(branchData, 'branchData')

    const getTotalDaysInMonth = (paymentMonth) => {
        if (!paymentMonth) return 30;
        const [year, month] = paymentMonth.split('-').map(Number);
        return new Date(year, month, 0).getDate();
    };

    const totalDays = getTotalDaysInMonth(salarySlip?.paymentMonth);

    const calculateSalary = (salary) => {
        const perDaySalary = salary?.basicSalary ? (salary.basicSalary / totalDays) : 0;

        const absentDeduction = Math.floor(perDaySalary * (salary?.monthlyAbsent || 0));
        const halfDayDeduction = Math.floor((perDaySalary / 2) * (salary?.monthlyHalfDay || 0));
        const totalDeductions = Math.floor(absentDeduction + halfDayDeduction + (salary?.expenseAmount || 0));

        const totalEarnings = Math.floor((salary?.basicSalary || 0) + (salary?.totalIncentive || 0));
        const netPay = Math.floor(totalEarnings - totalDeductions);

        return { totalEarnings, totalDeductions, netPay, absentDeduction, halfDayDeduction };
    };

    const { totalEarnings, totalDeductions, netPay, absentDeduction, halfDayDeduction } = calculateSalary(salarySlip);

    const printSlipHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Salary_Slip`
    });

    const downloadPdfHandler = async () => {
        const input = componentRef.current;
        const canvas = await html2canvas(input, { scale: 3 }); // Scale बढ़ाई ताकि टेक्स्ट सही दिखे
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });

        pdf.addImage(imgData, 'PNG', 10, 10, 190, 277); // A4 के लिए सही स्केलिंग
        pdf.save(`Salary_Slip.pdf`);
    };


    return (
        <PageContainer title="Salary Slip">
            <DashboardCard2 title="Salary Slip">
                <div ref={componentRef} style={{ padding: '20px', border: '1px solid #000', width: '75%', margin: 'auto' }} className="pdf-container">
                    {salarySlip ? (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                {/* <img src={branchData?.logo} alt="Salon Logo" style={{ width: '100px', height: '100px' }} /> */}
                                <div style={{ textAlign: 'center', flexGrow: 1 }}>
                                    <h2>{branchData?.branchName}</h2>
                                    <p>Address : {branchData?.address}</p>
                                    <p><strong>Contact:</strong> {branchData?.branchPhone} </p>
                                </div>
                            </div>

                            <table width="100%" cellPadding="5" style={{ borderCollapse: 'collapse', marginTop: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td><strong>Employee Name:</strong> {staff?.name}</td>
                                        <td><strong>Date of Joining:</strong> {staff?.dateOfJoining}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Pay Period:</strong> {salarySlip?.paymentMonth}</td>
                                        <td><strong>Department:</strong> {staff?.department}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Worked Days:</strong> {salarySlip?.monthlyPresent + salarySlip?.monthlyHalfDay}</td>
                                        <td><strong>Designation:</strong> {staff?.userType || 'NA'}</td>
                                    </tr>
                                    <tr>
                                        <td><strong> Absent:</strong> {salarySlip?.monthlyAbsent}</td>
                                        <td><strong> Half Days:</strong> {salarySlip?.monthlyHalfDay}</td>
                                    </tr>
                                    <tr>
                                        <td><strong> Present:</strong> {salarySlip?.monthlyPresent}</td>
                                        <td><strong> Week Off:</strong> {salarySlip?.monthlyWeekOffs}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table width="100%" border="1" cellPadding="5" style={{ borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>Earnings</th>
                                        <th>Amount</th>
                                        <th>Deductions</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Basic Salary</td>
                                        <td>₹{salarySlip?.basicSalary ? Math.round(salarySlip.basicSalary) : 0}</td>
                                        <td>Absent </td>
                                        <td>₹{(absentDeduction)}</td>
                                    </tr>
                                    <tr>
                                        <td>Incentive Pay</td>
                                        <td>₹{salarySlip?.totalIncentive ? Math.round(salarySlip.totalIncentive) : 0}</td>
                                        <td>Half-Day</td>
                                        <td>₹{(halfDayDeduction)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Expense</td>
                                        <td>₹{Math.floor(salarySlip?.expenseAmount || 0)}</td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4">
                                            <div style={{ display: 'flex', gap: '110px', fontWeight: 'bold', fontSize: '16px', padding: '10px' }}>
                                                <span style={{ display: 'flex', gap: '110px', minWidth: '150px' }}>
                                                    <span>Total Earnings:</span>
                                                    <span>₹{totalEarnings}</span>
                                                </span>
                                                <span style={{ display: 'flex', gap: '110px', minWidth: '150px' }}>
                                                    <span>Total Deductions:</span>
                                                    <span>₹{totalDeductions}</span>
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                </tfoot>
                            </table>

                            <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}>
                                Net Pay: ₹{netPay}
                            </h3>
                            <p style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>
                                <strong>Amount in Words:</strong> {convertNumberToWords(netPay)} rupees only
                            </p>

                            {/* Signature Section */}
                            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ textAlign: 'center', width: '30%' }}>
                                    <p><strong>Owner Signature</strong></p>
                                    <div style={{ borderTop: '1px solid black', width: '100px', margin: '10px auto' }}></div>
                                </div>
                                <div style={{ textAlign: 'center', width: '30%' }}>
                                    <p><strong>Employee Signature</strong></p>
                                    <div style={{ borderTop: '1px solid black', width: '100px', margin: '10px auto' }}></div>
                                </div>
                            </div>

                            {/* Footer */}
                            <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>
                                Created by <a href="https://yoursalon.in" target="_blank" rel="noopener noreferrer">YourSalon.in</a>
                            </div>

                        </div>
                    ) : (
                        <p>No salary slip available.</p>
                    )}
                </div>

                <Box display="flex" justifyContent="center" mt={2}>
                    <Button variant="contained" onClick={printSlipHandler} sx={{ margin: '10px' }}>Print</Button>
                    <Button variant="contained" onClick={downloadPdfHandler} sx={{ margin: '10px' }}>Download PDF</Button>
                </Box>
            </DashboardCard2>
        </PageContainer>
    );
};

export default SalarySlipPdf;





