// after current disable month
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { Box } from '@mui/system';
import Overview from './Overview';
import StaffWorkTable from './StaffWorkTable';
import TableViewFormate from './TableViewFormate';
import * as XLSX from 'xlsx';

const currentDate = () => {
  const newDate = new Date();
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
  const year = newDate.getFullYear();
  return { month, year };
};

const yearOptions = [
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
];

const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

const Attendance = () => {
  const [year, setYear] = useState(currentDate().year);
  const [month, setMonth] = useState(monthOptions[currentDate().month - 1].value);
  const [yearMonth, setYearMonth] = useState(`${currentDate().year}-${currentDate().month}`);
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [monthlyAttendanceData, setMonthlyAttendanceData] = useState(0);
  const [staffWorkData, setStaffWorkData] = useState([]);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [overviewData, setOverviewData] = useState([]);

  // Calculate the max year-month value
  const maxYearMonth = `${currentDate().year}-${String(currentDate().month).padStart(2, '0')}`;

  const { fetchAttendanceByStaffId, branchData, activeBranchId, fetchStaffWiseWork, staffOverview } = useMain();
  console.log(branchData, "branchData")
  const onApplyHandler = () => {
    getAttendance(selectedStaffId, activeBranchId);
  };

  const onStaffWorkHandler = () => {
    getStaffWork(selectedStaffId, activeBranchId, month);
  };

  const handleStaffChange = (event) => {
    setSelectedStaffId(event.target.value);
    setShowNoDataMessage(false);
  };

  const getStaffOverview = async (id, yearMonth) => {
    // const getStaffOverview = async (id, month, year) => {
    try {
      setLoading(true);
      const res = await staffOverview(id, activeBranchId, yearMonth);

      // const formattedMonth = ${year}-${month.toString().padStart(2, '0')}; // Format as 'YYYY-MM'
      // const res = await staffOverview(id, activeBranchId, formattedMonth);

      if (res.statusCode === 200) {
        setOverviewData(res?.data || []);
      }
    } catch (error) {
      console.error('Error fetching overview data:', error);
    } finally {
      setLoading(false);
    }
  };
  console.log(overviewData, "overviewDate");

  const getAttendance = async (id, branchId) => {
    const queryParams = `?branchId=${branchId}&month=${yearMonth}`;
    try {
      setLoading(true);
      const res = await fetchAttendanceByStaffId(id, queryParams);
      if (res.statusCode === 200) {
        setMonthlyAttendanceData(res?.data?.totalAttendance);
        setData(res?.data?.attendanceData || []);
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStaffWork = async (id, activeBranchId, monthName) => {
    const queryParams = `&staffId=${id}&monthName=${monthName}`;
    try {
      setLoading(true);
      const res = await fetchStaffWiseWork(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setStaffWorkData(res?.data || []);
        setShowNoDataMessage(res?.data?.length === 0);
      }
    } catch (error) {
      console.error('Error fetching staff work data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStaffId && activeBranchId) {
      getAttendance(selectedStaffId, activeBranchId);
      // getStaffOverview(selectedStaffId, yearMonth);
      getStaffOverview(selectedStaffId, yearMonth);
    }
    return () => {
      setData([]);
      setMonthlyAttendanceData(0);
      setOverviewData([]);
      setStaffWorkData([]);
    };
  }, [selectedStaffId, activeBranchId, yearMonth, month, year]);
  //console.log(staffWorkData,'staffWorkData')

  const exportToExcel = () => {
    // Staff name retrieval
    const selectedStaff = branchData?.staffs?.find(staff => staff._id === selectedStaffId);
    const staffName = selectedStaff ? selectedStaff.name : 'Unknown Staff';
  
    // Define headers
    const headers = ['Date', 'Day', 'In Time', 'Out Time', 'Attendance', 'Effective Hours', 'Appointments'];
  
    // Convert data to required format
    const excelData = data.map((item) => {
      const originalDate = item?.date;
      let formattedDate = '';
      if (originalDate) {
        const [year, month, day] = originalDate.split('-');
        formattedDate = `${day}-${month}-${year}`;
      }
  
      return {
        Date: formattedDate,
        Day: item?.day || '',
        'In Time': item?.attendance?.inTime || '--:--',
        'Out Time': item?.attendance?.outTime || '--:--',
        Attendance: item?.attendance?.status || '',
        'Effective Hours': item?.effectiveWorkInHours || '',
        Appointments: 'View Details',
      };
    });
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet([]);
  
    // Insert staff name at the top
    XLSX.utils.sheet_add_aoa(worksheet, [[`Staff Name: ${staffName}`]], { origin: 'A1' });
  
    // Insert headers at row 3
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A3' });
  
    // Insert data starting from row 4
    XLSX.utils.sheet_add_json(worksheet, excelData, { origin: 'A4', skipHeader: true });
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance Data');
  
    // Download the Excel file
    XLSX.writeFile(workbook, `Attendance_Report_${staffName}.xlsx`);
  };
  
  return (
    <PageContainer title="Attendance" description="This is staff attendance">
      <DashboardCard2 title="Staff Attendance">
        <Overview overviewData={overviewData} selectedStaffId={selectedStaffId} />
        <Grid container spacing={2} sx={{ mt: 1, mb: 3 }} alignItems="center">
          <Grid item xs={6}>
            <Select
              value={selectedStaffId || ''}
              onChange={handleStaffChange}
              displayEmpty
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                minWidth: '140px',
              }}
            >
              <MenuItem value="" disabled>
                Select Staff
              </MenuItem>
              {branchData?.staffs?.map((staff) => (
                <MenuItem key={staff._id} value={staff._id}>
                  {staff.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Button ko ek hi row me sahi align karna */}
          <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
            <TextField
              type="month"
              value={yearMonth}
              onChange={(e) => setYearMonth(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                '& input': {
                  fontSize: '0.8rem',
                  padding: '0.5rem',
                },
                width: '120px',
              }}
              inputProps={{
                max: maxYearMonth,
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={exportToExcel}
              sx={{ ml: 2 }}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <ShowLoader />
        ) : (
          <>
            {staffWorkData.length === 0 ? (
              showNoDataMessage ? (
                <div className="no-data-message">
                  No work has been done by selected Staff.
                </div>
              ) : (
                <TableViewFormate
                  data={data}
                  selectedStaffId={selectedStaffId}
                  getAttendance={getAttendance}
                  getStaffOverview={getStaffOverview}
                  yearMonth={yearMonth}
                />
              )
            ) : (
              <StaffWorkTable
                selectedStaffId={selectedStaffId}
                data={staffWorkData}
                getStaffWork={getStaffWork}
                getStaffOverview={getStaffOverview}
              />
            )}
          </>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default Attendance;