// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import {
//   Box,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Select,
//   Button,
//   Dialog,
//   DialogTitle,
//   Pagination,
//   DialogContent,
//   DialogContentText,
//   Typography,
//   DialogActions,
//   IconButton,
// } from '@mui/material';
// import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import FieldData from './FieldData';
// import SearchImg from '../../assets/search.svg';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import * as XLSX from 'xlsx';
// import Book1Client from '../../assets/excel/Book1Client.xlsx';
// import TextInputs from 'src/components/inputs/TextInputs';
// import ClinetSuggestion from '../Billings/ClinetSuggestion';
// import RadioInputs from 'src/components/inputs/RadioInputs';
// import { borderBottom, width } from '@mui/system';
// import { useLocation } from 'react-router';
// import Cfilter from './Cfilter';
// import { ClearIcon } from '@mui/x-date-pickers-pro';

// const filterMenu = [
//   { value: 'billsFrequency', label: 'Most Frequent' },
//   { value: 'totalBillsAmount', label: 'Highest billed' },
//   { value: 'ratings', label: 'Ratings' },
// ];

// const filterGenderWise = [
//   { value: 'Female', label: 'Female' },
//   { value: 'Male', label: 'Male' },
// ];

// const AllClients = () => {
//   const [formData, setFormData] = useState({
//     clientNumber: '', clientName: '', amountPaid: [
//       {
//         paymentType: 'Cash',
//         amount: '',
//       },
//     ],
//     gender: ''
//   });
//   const [clientSuggested, setClientSuggested] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [showNewClientFields, setShowNewClientFields] = useState(false);
//   const [clientExists, setClientExists] = useState(true);
//   const [allClientsData, setAllClientsData] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [uploadedData, setUploadedData] = useState(null);
//   const [data, setData] = useState([]);
//   const [checkedClients, setCheckedClients] = useState([]);
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [sortBy, setSortBy] = useState([]);
//   const [genderBy, setGenderBy] = useState([]);
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [filteredData, setFilteredData] = useState([]);
//   const [totalFilteredDataCount, setTotalFilteredDataCount] = useState(0);
//   const [openDialogue, setOpenDialogue] = useState(false);
//   const { fetchAllClients, activeBranchId, createManyClient, sendBulkSms, setShowMessage, walletPayment } =
//     useMain();

//   const { state } = useLocation();

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile) {
//       // Read the file using XLSX
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const data = new Uint8Array(e.target.result);
//         const workbook = XLSX.read(data, { type: 'array' });

//         // Assuming the first sheet contains the data
//         const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

//         // Convert the sheet data to JSON
//         const jsonData = XLSX.utils.sheet_to_json(firstSheet);

//         // Set the converted data in state
//         setUploadedData(jsonData);
//       };
//       reader.readAsArrayBuffer(selectedFile);
//     }
//   };

//   const handleFilterChange = (selectedValues) => {
//     const selectedSortBy = selectedValues.filter(val => filterMenu.some(opt => opt.value === val));
//     const selectedGenderBy = selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val));

//     setSortBy(selectedSortBy);
//     setGenderBy(selectedGenderBy);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     // setSortBy('');
//     setSortBy([]);
//     setSearch('');
//     // setGenderBy('');
//     setGenderBy([]);
//     getData();
//   };

//   const getData = async () => {
//     try {
//       setShowLoader(true);
//       const res = await fetchAllClients(activeBranchId);
//       if (res.statusCode === 200) {
//         setData(res?.data);
//       } else {
//         setShowMessage({ message: res.message || 'Failed to fetch clients', messageType: 'error' });
//       }
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getData();
//     }
//   }, [activeBranchId]);
//   console.log(data, 'dataa')
//   useEffect(() => {
//     applyFilters();
//   }, [limit, page, sortBy, genderBy, search, data]);

//   // const applyFilters = () => {
//   //   let filteredData = data?.filter((item) => {
//   //     let isNameMatch = true;
//   //     let isGender = true;

//   //     // Filter by gender
//   //     if (genderBy.length > 0) {
//   //       isGender = genderBy.includes(item?.gender);
//   //     }

//   //     // Filter by search term
//   //     if (search) {
//   //       isNameMatch =
//   //         item?.clientName?.toLowerCase().includes(search.toLowerCase()) ||
//   //         item.clientNumber.includes(search);
//   //     }

//   //     return isNameMatch && isGender;
//   //   });

//   //   // Sort the filtered data based on selected criteria
//   //   if (sortBy.length > 0) {
//   //     filteredData.sort((a, b) => {
//   //       for (const sort of sortBy) {
//   //         const aValue = a[sort];
//   //         const bValue = b[sort];
//   //         if (typeof aValue === 'number' && typeof bValue === 'number') {
//   //           const diff = bValue - aValue;
//   //           if (diff !== 0) return diff;  // Return the result of the first non-zero difference
//   //         }
//   //       }
//   //       return 0;
//   //     });
//   //   }

//   //   const startIndex = (page - 1) * limit;
//   //   const endIndex = startIndex + limit;
//   //   filteredData = filteredData.slice(startIndex, endIndex);
//   //   setFilteredData(filteredData);
//   // };

//   const applyFilters = () => {
//     let filteredData = data?.filter((item) => {
//       let isNameMatch = true;
//       let isGender = true;

//       // Filter by gender
//       if (genderBy.length > 0) {
//         isGender = genderBy.includes(item?.gender);
//       }

//       // Filter by search term
//       if (search) {
//         isNameMatch =
//           item?.clientName?.toLowerCase().includes(search.toLowerCase()) ||
//           item.clientNumber.includes(search);
//       }

//       return isNameMatch && isGender;
//     });

//     // Update total filtered data count
//     setTotalFilteredDataCount(filteredData.length);

//     // Sort the filtered data based on selected criteria
//     if (sortBy.length > 0) {
//       filteredData.sort((a, b) => {
//         for (const sort of sortBy) {
//           const aValue = a[sort];
//           const bValue = b[sort];
//           if (typeof aValue === 'number' && typeof bValue === 'number') {
//             const diff = bValue - aValue;
//             if (diff !== 0) return diff;  // Return the result of the first non-zero difference
//           }
//         }
//         return 0;
//       });
//     }

//     // Pagination logic
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilteredData(filteredData);

//     // Reset page if needed
//     if (page > Math.ceil(totalFilteredDataCount / limit)) {
//       setPage(1);
//     }
//   };


//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     if (!uploadedData) {
//       setShowMessage({ message: 'Please select a valid file', messageType: 'error' });
//       return;
//     }
//     try {
//       setShowLoader(true);
//       const res = await createManyClient(
//         activeBranchId,
//         JSON.stringify({ bulkData: uploadedData }),
//       );
//       if (res?.statusCode === 200) {
//         getData();
//         setShowMessage({
//           message: res.message || 'Bulk client upload successful',
//           messageType: 'success',
//         });
//       } else {
//         setShowMessage({
//           message: res.message || 'Bulk client upload failed',
//           messageType: 'error',
//         });
//       }
//     } catch (error) {
//       setShowMessage({
//         message: error.message || 'Bulk client upload failed',
//         messageType: 'error',
//       });
//     } finally {
//       setShowLoader(false);
//       closeModal();
//     }
//   };

//   const downloadExcelTemplate = () => {
//     const templateFileName = Book1Client;

//     // Create an anchor element to trigger the download
//     const a = document.createElement('a');
//     a.style.display = 'none';
//     a.href = templateFileName;
//     a.download = 'client_template.xlsx';

//     // Trigger the click event to download the file
//     document.body.appendChild(a);
//     a.click();

//     // Remove the anchor element
//     document.body.removeChild(a);
//   };

//   const sendBulkSmsHandler = async () => {
//     const bulkNumber = checkedClients.map((client) => client.clientNumber);
//     try {
//       const res = await sendBulkSms({ bulkNumber });
//       if (res.statusCode === 200) {
//         setShowMessage({
//           message: res.message || 'Bulk SMS sent successfully',
//           messageType: 'success',
//         });
//       } else {
//         setShowMessage({ message: res.message || 'Bulk SMS not sent', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       // getBranchData(activeBranchId);
//     }
//     return () => {
//       setAllClientsData([]);
//     };
//   }, [activeBranchId]);

//   const handleWalletPayment = async () => {
//     const { clientNumber, amountPaid } = formData;

//     // Validate clientNumber and paymentType
//     if (clientNumber === '' || clientNumber.length !== 10) {
//       return setShowMessage({ message: 'Client Number is required or invalid', messageType: 'error' });
//     }

//     // Check if client exists in allClientsData to get their current wallet amount
//     const existingClient = data.find(client => client.clientNumber === clientNumber);
//     let updatedAmountPaid = amountPaid;

//     if (existingClient) {
//       updatedAmountPaid = amountPaid.map(payment => {
//         const existingAmount = parseFloat(existingClient.walletAmount || 0); // Assuming 'walletAmount' holds the current wallet balance
//         return {
//           paymentType: payment.paymentType || '', // Ensure paymentType is set
//           amount: existingAmount + (parseFloat(payment.amount) || 0), // Add existing amount to new amount
//         };
//       });
//     }

//     let requestBody = {
//       clientNumber,
//       amountPaid: updatedAmountPaid,
//     };

//     if (!clientExists) {
//       requestBody = { ...formData, amountPaid: updatedAmountPaid };
//     }

//     try {
//       setLoading(true);
//       const res = await walletPayment(activeBranchId, requestBody);
//       if (res.statusCode === 200) {
//         setShowMessage({ message: res.message || 'Successfully Submitted Payment', messageType: 'success' });
//         setFormData({
//           clientNumber: '',
//           paymentType: '',
//           amountPaid: [{ paymentType: 'Cash', amount: '' }], // Reset amountPaid to initial state
//         });
//       } else {
//         setShowMessage({ message: res.message || 'Error Occurred while Submitting Payment', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
//     } finally {
//       handleCloseDialogue();
//       setLoading(false);
//     }
//   };

//   const handleOpenDialogue = () => {
//     setOpenDialogue(true);
//   };

//   const updateSuggested = (fieldName, inputValue) => {
//     if (fieldName === 'clientNumber') {
//       const suggestions = data.filter((client) =>
//         client.clientNumber?.toLowerCase().includes(inputValue.toLowerCase())
//       );
//       setClientSuggested(suggestions);
//       setClientExists(suggestions.length > 0);
//       setShowNewClientFields(suggestions.length === 0);
//     }
//   };
//   //console.log(clientSuggested, 'clientSuggested')
//   //console.log(clientExists, 'clientExists')



//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name.includes('amountPaid')) {
//       const index = parseInt(name.split('-')[1], 10); // Extract index from name
//       const field = name.split('-')[2]; // Extract field (paymentType/amount)
//       const updatedAmountPaid = [...formData.amountPaid];
//       updatedAmountPaid[index] = {
//         ...updatedAmountPaid[index],
//         [field]: value,
//       };
//       setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
//     } else {
//       setFormData((prev) => ({ ...prev, [name]: value }));
//     }

//     // Update client suggestions and manage new client fields based on input
//     if (name === 'clientNumber') {
//       updateSuggested(name, value);
//       // If the input matches an existing client, clear new client fields
//       if (clientExists && showNewClientFields) {
//         setShowNewClientFields(false);
//       } else if (!clientExists) {
//         setShowNewClientFields(true); // Show new client fields if no match
//       }
//     }

//     // Clear suggestions if clientName is being edited
//     if (name === 'clientName' && !clientExists) {
//       setClientSuggested([]);
//     }
//   }



//   const handleSuggestedClick = (client) => {
//     setFormData((prev) => ({
//       ...prev,
//       clientNumber: client.clientNumber,
//       clientName: client.clientName,
//     }));
//     setClientSuggested([]);
//     setShowNewClientFields(false); // Hide new client fields upon selection
//   };

//   const handleCloseDialogue = () => {
//     setOpenDialogue(false);
//     setFormData((prev) => ({ ...prev, clientNumber: '', paymentType: '' }));
//     setClientSuggested([]);
//     setShowNewClientFields(false); // Reset new client field visibility
//   }

//   const handleAmountPaidInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name.includes('amountPaid')) {
//       const index = parseInt(name.split('-')[1], 10); // Extract index from name
//       const field = name.split('-')[2]; // Extract field (paymentType/amount)
//       const updatedAmountPaid = [...formData.amountPaid];
//       updatedAmountPaid[index] = {
//         ...updatedAmountPaid[index],
//         [field]: field === 'amount' ? parseFloat(value) || 0 : value, // Ensure amount is a number
//       };
//       setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
//     } else {
//       setFormData((prev) => ({ ...prev, [name]: value }));
//     }
//   };


//   useEffect(() => {
//     if (state) {
//       setFormData((prev) => ({ ...prev, clientNumber: state?.clientNumber }));
//     }
//   }, [state]);

//   return (
//     <PageContainer title="Client History" description="This contains all products">
//       <DashboardCard2 title="Client Management">
//         <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '1.5rem', alignItems: 'start' }}>
//           <div style={{ marginBottom: '1.7rem' }}>
//             <InputLabel>Show per page</InputLabel>
//             <Select
//               style={{
//                 borderRadius: '7px',
//                 border: '0.5px solid #6174DD',
//                 padding: '4px 12px',
//                 background: '#FFF',
//                 height: '40px',
//                 width: '17%'
//               }}
//               value={limit}
//               onChange={handleLimitChange}
//             >
//               <MenuItem value={10}>10</MenuItem>
//               <MenuItem value={20}>20</MenuItem>
//               <MenuItem value={50}>50</MenuItem>
//             </Select>
//           </div>

//           <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem', alignItems: 'center' }}>


//             <div style={{ marginRight: '4rem', width: '98%' }}>
//               <Cfilter
//                 options={[
//                   { title: 'Sort By', items: filterMenu },
//                   { title: 'Gender', items: filterGenderWise }
//                 ]}
//                 value={[...genderBy, ...sortBy]}  // Spread both arrays to merge their values
//                 onChange={handleFilterChange}
//                 placeholder="Filters"
//                 style={{ marginRight: '1rem' }}
//               />
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => document.getElementById('search-input').focus()}>
//               <InputLabel style={{ marginLeft: '-5rem' }}>Search</InputLabel>
//               <div className="search-input" style={{ display: 'flex', marginLeft: '0.5rem' }}>
//                 <img src={SearchImg} alt="" />
//                 <input
//                   id="search-input"
//                   type="text"
//                   placeholder=""
//                   value={search}
//                   onChange={(e) => setSearch(e.target.value)}
//                   style={{ marginLeft: '0.5rem', borderRadius: '5px' }}
//                 />
//                 {search && (
//                   <IconButton
//                     onClick={() => {
//                       setSearch('');
//                     }}
//                     style={{ padding: 1 }}
//                   >
//                     <ClearIcon style={{
//                       // color: '#ff4d4d',
//                       color: '#000',
//                       // color: '#A2A3AE',
//                       marginRight: '-0.2rem', marginTop: '0.1rem',
//                       fontSize: '1.1rem'
//                     }} />
//                   </IconButton>
//                 )}
//               </div>
//             </div>

//             <div style={{
//               marginRight: '1rem', // Adjusted margin here
//               width: '250%',
//               marginTop: '1.7rem',
//             }}>
//               <button
//                 style={{
//                   backgroundColor: '#6174DD',
//                   padding: '10px 15px',
//                   color: '#fff',
//                   borderRadius: '5px',
//                   fontSize: '14px',
//                   border: 'none',
//                   cursor: 'pointer',
//                   width: '41%',
//                 }}
//                 onClick={handleOpenDialogue}
//               >
//                 Wallet
//               </button>
//             </div>

//             {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.7rem' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '41px',
//                   width: '5.2rem',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </div> */}
//           </div>
//         </div>

//         <Grid container justifyContent="flex-end">
//           <Grid item justifyContent="flex-end">
//             <Button
//               variant="contained"
//               color="primary"
//               target="_blank"
//               sx={{
//                 background: '#6174DD',
//               }}
//               onClick={openModal}
//             >
//               Bulk Client Upload
//             </Button>
//           </Grid>
//         </Grid>

//         {/* ********************* Client-Wallet-Added****************** */}
//         <Dialog
//           open={openDialogue}
//           onClose={handleCloseDialogue}
//           maxWidth="sm"
//           fullWidth
//           sx={{
//             '& .MuiDialog-paper': {
//               padding: '20px',
//               minHeight: 'auto',
//             },
//           }}
//         >
//           <DialogTitle>Wallet Amount</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               Here you can add an advance amount
//             </DialogContentText>

//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
//                   <TextInputs
//                     title={'Client Number'}
//                     name={'clientNumber'}
//                     placeholder="Enter client number"
//                     value={formData.clientNumber}
//                     handleChange={handleChange}
//                     type={'text'}
//                   />
//                 </Box>
//               </Grid> 
//               {/* Show client suggestions only if client exists and clientNumber is not empty */}
//               {clientExists && formData.clientNumber && (
//                 <Grid item xs={12}>
//                   <ClinetSuggestion
//                     data={clientSuggested}
//                     handleSuggestedClick={handleSuggestedClick}
//                   />
//                 </Grid>
//               )}

//               {/* New Client Fields */}
//               {!clientExists && showNewClientFields && (
//                 <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
//                   <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
//                     <Box sx={{ borderBottom: 'none', width: '100%' }}>
//                       <TextInputs
//                         title={'Client Name'}
//                         name={'clientName'}
//                         value={formData.clientName}
//                         handleChange={handleChange}
//                         type={'text'}
//                       />
//                     </Box>
//                     <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
//                       <RadioInputs
//                         title={'Gender'}
//                         name={'gender'}
//                         value={formData.gender}
//                         handleChange={handleChange}
//                         options={[
//                           { title: 'Male', value: 'Male' },
//                           { title: 'Female', value: 'Female' },
//                         ]}
//                       />
//                     </Box>
//                   </Box>
//                 </Grid>
//               )}
//             </Grid>

//             {/* Amount Payment Section */}
//             <Grid container spacing={3}>
//               {formData.amountPaid.map((payment, index) => (
//                 <Grid item xs={12} key={index}>
//                   <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
//                     <Box sx={{ borderBottom: 'none', width: '22.5rem', paddingTop: '15px' }}>
//                       <Typography component="label"><strong>Payment Type</strong></Typography>
//                       <select
//                         className="form-select"
//                         aria-label="Default select example"
//                         name={`amountPaid-${index}-paymentType`}
//                         value={payment.paymentType}
//                         onChange={handleAmountPaidInputChange}
//                         style={{
//                           display: 'block',
//                           width: '100%',
//                           padding: '13px',
//                           fontSize: '16px',
//                           margin: '8px 0',
//                           borderRadius: '4px',
//                         }}
//                       >
//                         <option value="">--Select--</option>
//                         <option value="Cash">Cash</option>
//                         <option value="Credit/Debit Card">Credit/Debit Card</option>
//                         <option value="Paytm">Paytm</option>
//                         <option value="PhonePe">PhonePe</option>
//                         <option value="Gpay">Gpay</option>
//                       </select>
//                     </Box>
//                     <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
//                       <TextInputs
//                         title={'Amount'}
//                         name={`amountPaid-${index}-amount`}
//                         value={payment.amount}
//                         handleChange={handleAmountPaidInputChange}
//                         type={'number'}
//                         placeholder="Amount"
//                       />
//                     </Box>
//                   </Box>
//                 </Grid>
//               ))}
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialogue} style={{ backgroundColor: '#FF6666', color: 'white' }}>
//               Cancel
//             </Button>
//             <Button onClick={handleWalletPayment} style={{ backgroundColor: '#6174DD', color: 'white' }}>
//               {loading ? 'Submitting...' : 'Submit'}
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/*----------------------Modal Dialog-------------------*/}
//         <Dialog open={isModalOpen} onClose={closeModal}>
//           <DialogTitle>Create Many Client</DialogTitle>
//           <form onSubmit={handleFormSubmit}>
//             <Box p={2}>
//               <input
//                 type="file"
//                 accept=".xlsx, .xls" // Set accepted file types
//                 onChange={handleFileChange}
//               />
//             </Box>
//             {showLoader && <ShowLoader value={showLoader} />}
//             <Box p={2}>
//               <Button type="submit" variant="contained" color="primary">
//                 Submit
//               </Button>
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 sx={{ ml: 2 }}
//                 onClick={downloadExcelTemplate}
//               >
//                 <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
//                 Sample Download
//               </Button>
//             </Box>
//           </form>
//         </Dialog>

//         {/* -------------------------table start-------------------------- */}

//         {showLoader ? (
//           <ShowLoader />
//         ) : filteredData && filteredData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   {/* Use the fetched client data instead of the static data */}
//                   <FieldData
//                     data={filteredData}
//                     setData={setData}
//                     getData={getData}
//                     checkedClients={checkedClients}
//                     setCheckedClients={setCheckedClients}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}

//         {/* <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={Math.ceil(data.length / limit)}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//             disabled={data.length === 0}
//           />
//         </Box> */}
//         {totalFilteredDataCount > 0 && (
//           <Box display={'flex'} justifyContent={'flex-end'}>
//             <Pagination
//               count={Math.ceil(totalFilteredDataCount / limit)}
//               page={page}
//               color="primary"
//               onChange={(e, page) => setPage(page)}
//               disabled={totalFilteredDataCount === 0}
//             />
//           </Box>
//         )}

//         {checkedClients.length !== 0 && (
//           <Box
//             style={{
//               width: '100%',
//               display: 'flex',
//               justifyContent: 'flex-end',
//               marginTop: '20px',
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'flex-end',
//                 gap: '10px',
//                 margin: '10px 0px 0px 0px',
//               }}
//             >
//               <div style={{ width: '200px' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   target="_blank"
//                   sx={{
//                     background: '#25d366',
//                     '&:hover': { background: '#25d366' },
//                   }}
//                 >
//                   <WhatsAppIcon sx={{ margin: '0 5px' }} />
//                   Bulk WhatsApp
//                 </Button>
//               </div>
//               <div style={{ width: '200px' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={sendBulkSmsHandler}
//                 >
//                   Send Bulk Sms
//                 </Button>
//               </div>
//             </div>
//           </Box>
//         )}
//       </DashboardCard2>
//     </PageContainer >
//   );
// };

// export default AllClients;

import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogTitle,
  Pagination,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  IconButton,
} from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import FieldData from './FieldData';
import SearchImg from '../../assets/search.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import * as XLSX from 'xlsx';
import Book1Client from '../../assets/excel/Book1Client.xlsx';
import TextInputs from 'src/components/inputs/TextInputs';
import ClinetSuggestion from '../Billings/ClinetSuggestion';
import RadioInputs from 'src/components/inputs/RadioInputs';
import { borderBottom, width } from '@mui/system';
import { useLocation } from 'react-router';
import Cfilter from './Cfilter';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const filterMenu = [
  { value: 'billsFrequency', label: 'Most Frequent' },
  { value: 'totalBillsAmount', label: 'Highest billed' },
  { value: 'ratings', label: 'Ratings' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllClients = () => {
  const [formData, setFormData] = useState({
    clientNumber: '', clientName: '', amountPaid: [
      {
        paymentType: 'Cash',
        amount: '',
      },
    ],
    gender: ''
  });
  const [clientSuggested, setClientSuggested] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNewClientFields, setShowNewClientFields] = useState(false);
  const [clientExists, setClientExists] = useState(true);
  const [allClientsData, setAllClientsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [data, setData] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState([]);
  const [genderBy, setGenderBy] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [totalFilteredDataCount, setTotalFilteredDataCount] = useState(0);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [showLossClient, setShowLossClient] = useState(false);
  const [totalClientsCount, setTotalClientsCount] = useState(0); // Total count for pagination
  const { fetchAllClients, getAllLossClient, activeBranchId, createManyClient, sendBulkSms, setShowMessage, walletPayment } =
    useMain();

  const { state } = useLocation();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file using XLSX
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet contains the data
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        // Set the converted data in state
        setUploadedData(jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleFilterChange = (selectedValues) => {
    const selectedSortBy = selectedValues.filter(val => filterMenu.some(opt => opt.value === val));
    const selectedGenderBy = selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val));

    setSortBy(selectedSortBy);
    setGenderBy(selectedGenderBy);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    // setSortBy('');
    setSortBy([]);
    setSearch('');
    // setGenderBy('');
    setGenderBy([]);
    getData();
  };

  const getData = async (lossClients = false) => {
    try {
      setShowLoader(true);
      const res = lossClients
        ? await getAllLossClient(activeBranchId)
        : await fetchAllClients(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data);
        setTotalClientsCount(res.data.length);
      } else {
        setShowMessage({ message: res.message || 'Failed to fetch clients', messageType: 'error' });
      }
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData(false);
    }
  }, [activeBranchId]);

  console.log(data, 'dataa')
  useEffect(() => {
    applyFilters();
  }, [limit, page, sortBy, genderBy, search, data]);

  // const applyFilters = () => {
  //   let filteredData = data?.filter((item) => {
  //     let isNameMatch = true;
  //     let isGender = true;

  //     // Filter by gender
  //     if (genderBy.length > 0) {
  //       isGender = genderBy.includes(item?.gender);
  //     }

  //     // Filter by search term
  //     if (search) {
  //       isNameMatch =
  //         item?.clientName?.toLowerCase().includes(search.toLowerCase()) ||
  //         item.clientNumber.includes(search);
  //     }

  //     return isNameMatch && isGender;
  //   });

  //   // Sort the filtered data based on selected criteria
  //   if (sortBy.length > 0) {
  //     filteredData.sort((a, b) => {
  //       for (const sort of sortBy) {
  //         const aValue = a[sort];
  //         const bValue = b[sort];
  //         if (typeof aValue === 'number' && typeof bValue === 'number') {
  //           const diff = bValue - aValue;
  //           if (diff !== 0) return diff;  // Return the result of the first non-zero difference
  //         }
  //       }
  //       return 0;
  //     });
  //   }

  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilteredData(filteredData);
  // };

  const applyFilters = () => {
    let filteredData = data?.filter((item) => {
      let isNameMatch = true;
      let isGender = true;

      // Filter by gender
      if (genderBy.length > 0) {
        isGender = genderBy.includes(item?.gender);
      }

      // Filter by search term
      if (search) {
        isNameMatch =
          item?.clientName?.toLowerCase().includes(search.toLowerCase()) ||
          item.clientNumber.includes(search);
      }

      return isNameMatch && isGender;
    });

    // Update total filtered data count
    setTotalFilteredDataCount(filteredData.length);

    // Sort the filtered data based on selected criteria
    if (sortBy.length > 0) {
      filteredData.sort((a, b) => {
        for (const sort of sortBy) {
          const aValue = a[sort];
          const bValue = b[sort];
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            const diff = bValue - aValue;
            if (diff !== 0) return diff;  // Return the result of the first non-zero difference
          }
        }
        return 0;
      });
    }
    // Pagination logic
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilteredData(filteredData);

    // Reset page if needed
    if (page > Math.ceil(totalFilteredDataCount / limit)) {
      setPage(1);
    }
  };


  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!uploadedData) {
      setShowMessage({ message: 'Please select a valid file', messageType: 'error' });
      return;
    }
    try {
      setShowLoader(true);
      const res = await createManyClient(
        activeBranchId,
        JSON.stringify({ bulkData: uploadedData }),
      );
      if (res?.statusCode === 200) {
        getData();
        setShowMessage({
          message: res.message || 'Bulk client upload successful',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res.message || 'Bulk client upload failed',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Bulk client upload failed',
        messageType: 'error',
      });
    } finally {
      setShowLoader(false);
      closeModal();
    }
  };

  const downloadExcelTemplate = () => {
    const templateFileName = Book1Client;

    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = templateFileName;
    a.download = 'client_template.xlsx';

    // Trigger the click event to download the file
    document.body.appendChild(a);
    a.click();

    // Remove the anchor element
    document.body.removeChild(a);
  };

  const sendBulkSmsHandler = async () => {
    const bulkNumber = checkedClients.map((client) => client.clientNumber);
    try {
      const res = await sendBulkSms({ bulkNumber });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Bulk SMS sent successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Bulk SMS not sent', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      // getBranchData(activeBranchId);
    }
    return () => {
      setAllClientsData([]);
    };
  }, [activeBranchId]);

  const handleWalletPayment = async () => {
    const { clientNumber, amountPaid } = formData;

    // Validate clientNumber and paymentType
    if (clientNumber === '' || clientNumber.length !== 10) {
      return setShowMessage({ message: 'Client Number is required or invalid', messageType: 'error' });
    }

    // Check if client exists in allClientsData to get their current wallet amount
    const existingClient = data.find(client => client.clientNumber === clientNumber);
    let updatedAmountPaid = amountPaid;

    if (existingClient) {
      updatedAmountPaid = amountPaid.map(payment => {
        const existingAmount = parseFloat(existingClient.walletAmount || 0); // Assuming 'walletAmount' holds the current wallet balance
        return {
          paymentType: payment.paymentType || '', // Ensure paymentType is set
          amount: existingAmount + (parseFloat(payment.amount) || 0), // Add existing amount to new amount
        };
      });
    }

    let requestBody = {
      clientNumber,
      amountPaid: updatedAmountPaid,
    };

    if (!clientExists) {
      requestBody = { ...formData, amountPaid: updatedAmountPaid };
    }

    try {
      setLoading(true);
      const res = await walletPayment(activeBranchId, requestBody);
      if (res.statusCode === 200) {
        setShowMessage({ message: res.message || 'Successfully Submitted Payment', messageType: 'success' });
        setFormData({
          clientNumber: '',
          paymentType: '',
          amountPaid: [{ paymentType: 'Cash', amount: '' }], // Reset amountPaid to initial state
        });
      } else {
        setShowMessage({ message: res.message || 'Error Occurred while Submitting Payment', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      handleCloseDialogue();
      setLoading(false);
    }
  };

  const handleOpenDialogue = () => {
    setOpenDialogue(true);
  };

  const updateSuggested = (fieldName, inputValue) => {
    if (fieldName === 'clientNumber') {
      const suggestions = data.filter((client) =>
        client.clientNumber?.toLowerCase().includes(inputValue.toLowerCase())
      );
      setClientSuggested(suggestions);
      setClientExists(suggestions.length > 0);
      setShowNewClientFields(suggestions.length === 0);
    }
  };
  //console.log(clientSuggested, 'clientSuggested')
  //console.log(clientExists, 'clientExists')



  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes('amountPaid')) {
      const index = parseInt(name.split('-')[1], 10); // Extract index from name
      const field = name.split('-')[2]; // Extract field (paymentType/amount)
      const updatedAmountPaid = [...formData.amountPaid];
      updatedAmountPaid[index] = {
        ...updatedAmountPaid[index],
        [field]: value,
      };
      setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    // Update client suggestions and manage new client fields based on input
    if (name === 'clientNumber') {
      updateSuggested(name, value);
      // If the input matches an existing client, clear new client fields
      if (clientExists && showNewClientFields) {
        setShowNewClientFields(false);
      } else if (!clientExists) {
        setShowNewClientFields(true); // Show new client fields if no match
      }
    }

    // Clear suggestions if clientName is being edited
    if (name === 'clientName' && !clientExists) {
      setClientSuggested([]);
    }
  }



  const handleSuggestedClick = (client) => {
    setFormData((prev) => ({
      ...prev,
      clientNumber: client.clientNumber,
      clientName: client.clientName,
    }));
    setClientSuggested([]);
    setShowNewClientFields(false); // Hide new client fields upon selection
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
    setFormData((prev) => ({ ...prev, clientNumber: '', paymentType: '' }));
    setClientSuggested([]);
    setShowNewClientFields(false); // Reset new client field visibility
  }

  const handleAmountPaidInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('amountPaid')) {
      const index = parseInt(name.split('-')[1], 10); // Extract index from name
      const field = name.split('-')[2]; // Extract field (paymentType/amount)
      const updatedAmountPaid = [...formData.amountPaid];
      updatedAmountPaid[index] = {
        ...updatedAmountPaid[index],
        [field]: field === 'amount' ? parseFloat(value) || 0 : value, // Ensure amount is a number
      };
      setFormData((prev) => ({ ...prev, amountPaid: updatedAmountPaid }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };


  useEffect(() => {
    if (state) {
      setFormData((prev) => ({ ...prev, clientNumber: state?.clientNumber }));
    }
  }, [state]);

  // Toggle Function for Loss Clients
  const toggleClientType = () => {
    setShowLossClient((prev) => !prev);
    getData(!showLossClient); // Fetch Data Based on Toggle
  }

  return (
    <PageContainer title="Client History" description="This contains all products">
      <DashboardCard2 title="Client Management">
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '1.5rem', alignItems: 'start' }}>
          <div style={{ marginBottom: '1.7rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                width: '17%'
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem', alignItems: 'center' }}>


            <div style={{ marginRight: '4rem', width: '98%' }}>
              <Cfilter
                options={[
                  { title: 'Sort By', items: filterMenu },
                  { title: 'Gender', items: filterGenderWise }
                ]}
                value={[...genderBy, ...sortBy]}  // Spread both arrays to merge their values
                onChange={handleFilterChange}
                placeholder="Filters"
                style={{ marginRight: '1rem' }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ marginLeft: '-5rem' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', marginLeft: '0.5rem' }}>
                <img src={SearchImg} alt="" />
                <input
                  id="search-input"
                  type="text"
                  placeholder=""
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ marginLeft: '0.5rem', borderRadius: '5px' }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      // color: '#ff4d4d',
                      color: '#000',
                      // color: '#A2A3AE',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>

            <div style={{
              marginRight: '1rem', // Adjusted margin here
              width: '250%',
              marginTop: '1.7rem',
            }}>
              <button
                style={{
                  backgroundColor: '#6174DD',
                  padding: '10px 15px',
                  color: '#fff',
                  borderRadius: '5px',
                  fontSize: '14px',
                  border: 'none',
                  cursor: 'pointer',
                  width: '41%',
                }}
                onClick={handleOpenDialogue}
              >
                Wallet
              </button>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.7rem' }}>
              <Button
                variant="contained"vv
                color="primary"
                sx={{
                  height: '41px',
                  width: '5.2rem',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </div> */}
          </div>
        </div>


        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ background: showLossClient ? '#6174DD' : '#FF6B6B' }}
              onClick={toggleClientType}
            >
              {showLossClient ? 'Show All Clients' : 'Show Lost Clients'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              sx={{ background: '#6174DD' }}
              onClick={openModal}
            >
              Bulk Client Upload
            </Button>
          </Grid>
        </Grid>


        {/* ********************* Client-Wallet-Added****************** */}
        <Dialog
          open={openDialogue}
          onClose={handleCloseDialogue}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              padding: '20px',
              minHeight: 'auto',
            },
          }}
        >
          <DialogTitle>Wallet Amount</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here you can add an advance amount
            </DialogContentText>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
                  <TextInputs
                    title={'Client Number'}
                    name={'clientNumber'}
                    placeholder="Enter client number"
                    value={formData.clientNumber}
                    handleChange={handleChange}
                    type={'text'}
                  />
                </Box>
              </Grid>
              {/* Show client suggestions only if client exists and clientNumber is not empty */}
              {clientExists && formData.clientNumber && (
                <Grid item xs={12}>
                  <ClinetSuggestion
                    data={clientSuggested}
                    handleSuggestedClick={handleSuggestedClick}
                  />
                </Grid>
              )}

              {/* New Client Fields */}
              {!clientExists && showNewClientFields && (
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ borderBottom: 'none', width: '100%' }}>
                      <TextInputs
                        title={'Client Name'}
                        name={'clientName'}
                        value={formData.clientName}
                        handleChange={handleChange}
                        type={'text'}
                      />
                    </Box>
                    <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
                      <RadioInputs
                        title={'Gender'}
                        name={'gender'}
                        value={formData.gender}
                        handleChange={handleChange}
                        options={[
                          { title: 'Male', value: 'Male' },
                          { title: 'Female', value: 'Female' },
                        ]}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>

            {/* Amount Payment Section */}
            <Grid container spacing={3}>
              {formData.amountPaid.map((payment, index) => (
                <Grid item xs={12} key={index}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ borderBottom: 'none', width: '22.5rem', paddingTop: '15px' }}>
                      <Typography component="label"><strong>Payment Type</strong></Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name={`amountPaid-${index}-paymentType`}
                        value={payment.paymentType}
                        onChange={handleAmountPaidInputChange}
                        style={{
                          display: 'block',
                          width: '100%',
                          padding: '13px',
                          fontSize: '16px',
                          margin: '8px 0',
                          borderRadius: '4px',
                        }}
                      >
                        <option value="">--Select--</option>
                        <option value="Cash">Cash</option>
                        <option value="Credit/Debit Card">Credit/Debit Card</option>
                        <option value="Paytm">Paytm</option>
                        <option value="PhonePe">PhonePe</option>
                        <option value="Gpay">Gpay</option>
                      </select>
                    </Box>
                    <Box sx={{ borderBottom: 'none', width: '22.5rem' }}>
                      <TextInputs
                        title={'Amount'}
                        name={`amountPaid-${index}-amount`}
                        value={payment.amount}
                        handleChange={handleAmountPaidInputChange}
                        type={'number'}
                        placeholder="Amount"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogue} style={{ backgroundColor: '#FF6666', color: 'white' }}>
              Cancel
            </Button>
            <Button onClick={handleWalletPayment} style={{ backgroundColor: '#6174DD', color: 'white' }}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>

        {/*----------------------Modal Dialog-------------------*/}
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Create Many Client</DialogTitle>
          <form onSubmit={handleFormSubmit}>
            <Box p={2}>
              <input
                type="file"
                accept=".xlsx, .xls" // Set accepted file types
                onChange={handleFileChange}
              />
            </Box>
            {showLoader && <ShowLoader value={showLoader} />}
            <Box p={2}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 2 }}
                onClick={downloadExcelTemplate}
              >
                <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
                Sample Download
              </Button>
            </Box>
          </form>
        </Dialog>

        {/* -------------------------table start-------------------------- */}

        {showLoader ? (
          <ShowLoader />
        ) : filteredData && filteredData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  {/* Use the fetched client data instead of the static data */}
                  <FieldData
                    data={filteredData}
                    setData={setData}
                    getData={() => getData(showLossClient)}
                    checkedClients={checkedClients}
                    setCheckedClients={setCheckedClients}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}

        {/* <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(data.length / limit)}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
            disabled={data.length === 0}
          />
        </Box> */}
        {/* Pagination */}
        {totalClientsCount > 0 && (
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
              <Pagination
                count={Math.ceil(totalClientsCount / limit)}
                page={page}
                color="primary"
                onChange={(e, newPage) => {
                  setPage(newPage);
                  getData(showLossClient); // Fetch data on page change
                }}
                disabled={totalClientsCount === 0}
              />
            </Box>
          )}

        {checkedClients.length !== 0 && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                margin: '10px 0px 0px 0px',
              }}
            >
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  target="_blank"
                  sx={{
                    background: '#25d366',
                    '&:hover': { background: '#25d366' },
                  }}
                >
                  <WhatsAppIcon sx={{ margin: '0 5px' }} />
                  Bulk WhatsApp
                </Button>
              </div>
              <div style={{ width: '200px' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={sendBulkSmsHandler}
                >
                  Send Bulk Sms
                </Button>
              </div>
            </div>
          </Box>
        )}
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllClients;