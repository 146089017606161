import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { LiaEdit } from 'react-icons/lia';
// import { yellow } from '@mui/material/colors';

const   TableViewFormate = ({ data, selectedStaffId, getAttendance, getStaffOverview, month, year, yearMonth }) => {
  const [appointments, setAppointments] = useState([]);
  const [isAppointmentDrawerOpen, setIsAppointmentDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const { fetchStaffAppointmentsDateWise, activeBranchId, staffStatusUpdate, setShowMessage } =
    useMain();
  // console.log(data, "dataaaaaaaa")

  const openAppointmentDrawer = (date) => {
    getAppointments(selectedStaffId, date);
    setIsAppointmentDrawerOpen(true);
  };

  const getAppointments = async (activeStaffId, date) => {
    const formattedDate = formatDate(date);
    const queryParams = `?staffId=${activeStaffId}&date=${formattedDate}&branchId=${activeBranchId}`;
    try {
      setLoading(true);
      const res = await fetchStaffAppointmentsDateWise(queryParams);
      if (res.statusCode === 200) {
        setAppointments(res.data);
      } else {
        setAppointments([]);
      }
    } catch (error) {
      setAppointments([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setSelectedAttendance({});
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedAttendance({});
  };

  const handleImageClick = (attendance) => {
    setSelectedAttendance(attendance);
    setOpenImageDialog(true);
  };

  const handleEditClick = (attendance) => {
    setSelectedAttendance(attendance);
    setSelectedStatus(attendance?.status)
    setOpenEditDialog(true);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    setLoading(true); // Start loading
    const formattedDate = formatDate(selectedAttendance.date);
    const queryParams = `?date=${formattedDate}&branchId=${activeBranchId}&status=${selectedStatus}`;

    try {
      const response = await staffStatusUpdate(selectedStaffId, queryParams);

      if (response?.statusCode === 200) {
        setShowMessage({
          message: response.message || 'Staff status updated successfully',
          messageType: 'success',
        });

        // Fetch the updated data to refresh the page
        await getAttendance(selectedStaffId, activeBranchId);
        // await getStaffOverview(selectedStaffId,month, year, activeBranchId)
        await getStaffOverview(selectedStaffId, yearMonth);

        handleCloseEditDialog(); // Close the dialog
      } else {
        setShowMessage({
          message: response.message || 'Failed to update staff status',
          messageType: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to Update status', error);
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  const currentDate = () => {
    const newDate = new Date();
    const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
    const year = newDate.getFullYear();
    return { month, year };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of the day
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Set to start of tomorrow
    return new Date(date) >= tomorrow;
  };
  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container">
        <thead className="table-thead">
          <tr>
            {['Date', 'Day', 'In Time', 'Out Time', 'Attendance', 'Effective Hours', 'Appointments'].map((h, index) => (
              <th key={index} style={{ width: '100%' }}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item, index) => {
            const originalDate = item?.date;

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedAttendanceDate = '';
            if (originalDate) {
              const [year, month, day] = originalDate.split('-');
              formattedAttendanceDate = `${day}-${month}-${year}`;
            }

            const futureDate = isFutureDate(originalDate);
            const blurStyle = futureDate ? { opacity: 0.5, pointerEvents: 'none' } : {}
            return (
              <tr key={index}>
                <td style={{ width: '100%' }}>{formattedAttendanceDate}</td>
                <td style={{ width: '100%' }}>{item?.day}</td>
                <td style={{ width: '100%' }}>
                  {item?.attendance?.inTime ? item.attendance.inTime : '--:--'}
                </td>
                <td style={{ width: '100%' }}>
                  {item?.attendance?.outTime ? item.attendance.outTime : '--:--'}
                </td>

                <td style={{ width: '100%' }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      top: '60px',
                      right: '55px',
                      color: item?.attendance?.remarkTick ? 'green' : 'red',
                      cursor: item?.attendance?.remarkTick ? 'pointer' : 'default',
                      ...blurStyle,
                    }}
                  >
                    {(item?.attendance?.status === 'Present' || item?.attendance?.remarkTick) && (
                      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <button
                          style={{
                            padding: '6px 10px',
                            backgroundColor: '#47c247',
                            color: 'white',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                          }}
                          onClick={() =>
                            handleImageClick({
                              date: item?.date,
                              liveImage: item?.attendance?.liveImage,
                              remarkTick: item?.attendance?.remarkTick,
                            })
                          }
                          disabled={futureDate}

                        >
                          Present
                        </button>
                        <LiaEdit
                          style={{ cursor: 'pointer' }}
                          size={20}
                          color="black"
                          onClick={() =>
                            (item?.attendance?.status === 'Present' ||
                              item?.attendance?.remarkTick === true) &&
                            handleEditClick({
                              date: item?.date,
                              status: item?.attendance?.status,
                            })
                          }
                        />
                      </div>
                    )}

                    {item?.attendance?.status === 'Absent' && (
                      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <button
                          style={{
                            padding: '6px 10px',
                            backgroundColor: `${futureDate ? '#808080' : '#FF2511'}`,
                            color: 'white',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                          }}
                          onClick={() =>
                            handleImageClick({
                              date: item?.date,
                              liveImage: item?.attendance?.liveImage,
                            })
                          }
                          disabled={futureDate}
                        >
                          {futureDate ? 'Punch In' : 'Absent'}
                        </button>
                        <LiaEdit
                          style={{ cursor: 'pointer' }}
                          size={20}
                          color="black"
                          onClick={() =>
                            item?.attendance?.status === 'Absent' &&
                            handleEditClick({
                              date: item?.date,
                              status: item?.attendance?.status,
                            })
                          }
                        />
                      </div>
                    )}

                    {item?.attendance?.status === 'Halfday' && (
                      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <button
                          style={{
                            padding: '6px 10px',
                            backgroundColor: '#FFB001',
                            color: 'white',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                          }}
                          onClick={() =>
                            handleImageClick({
                              date: item?.date,
                              liveImage: item?.attendance?.liveImage,
                            })
                          }
                          disabled={futureDate}
                        >
                          Halfday
                        </button>
                        <LiaEdit
                          style={{ cursor: 'pointer' }}
                          size={20}
                          color="black"
                          onClick={() =>
                            item?.attendance?.status === 'Halfday' &&
                            handleEditClick({
                              date: item?.date,
                              status: item?.attendance?.status,
                            })
                          }
                        />
                      </div>
                    )}

                    {item?.attendance?.status === 'Week Off' && (
                      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <button
                          style={{
                            padding: '6px 10px',
                            backgroundColor: '#2668F4',
                            color: 'white',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                          }}
                          onClick={() =>
                            handleImageClick({
                              date: item?.date,
                              liveImage: item?.attendance?.liveImage,
                            })
                          }
                          disabled={futureDate}
                        >
                          Week Off
                        </button>
                        <LiaEdit
                          style={{ cursor: 'pointer' }}
                          size={20}
                          color="black"
                          onClick={() =>
                            item?.attendance?.status === 'Week Off' &&
                            handleEditClick({
                              date: item?.date,
                              status: item?.attendance?.status,
                            })
                          }
                        />
                      </div>
                    )}
                  </Typography>
                </td>
                <td style={{ width: '100%' }}>{item?.effectiveWorkInHours}</td>
                <td style={{ width: '100%' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => openAppointmentDrawer(item.date)}
                  >
                    Appointments
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Image Dialog */}
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Attendance Image</DialogTitle>
        <DialogContent>
          {selectedAttendance.liveImage ? (
            <img
              src={selectedAttendance.liveImage.url}
              alt="Attendance"
              style={{ width: '100%', marginTop: '10px' }}
            />
          ) : (
            'No Data Found'
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Attendance</DialogTitle>
        <DialogContent>
          {(selectedAttendance.date || selectedAttendance?.remarkTick === true) && (
            <div>
              {/* Edit form fields here */}
              Attendance details for {selectedAttendance.date}
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '2.8rem' }}>
                  <label>Present</label>
                  <input
                    type="radio"
                    name="status"
                    value="Present"
                    checked={selectedStatus === 'Present'}
                    onChange={handleStatusChange}
                    style={{ transform: 'scale(1.6)' }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '3rem' }}>
                  <label>Absent</label>
                  <input
                    type="radio"
                    name="status"
                    value="Absent"
                    checked={selectedStatus === 'Absent'}
                    onChange={handleStatusChange}
                    style={{ transform: 'scale(1.6)' }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '2.7rem' }}>
                  <label>Halfday</label>
                  <input
                    type="radio"
                    name="status"
                    value="Halfday"
                    checked={selectedStatus === 'Halfday'}
                    onChange={handleStatusChange}
                    style={{ transform: 'scale(1.6)' }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '1.9rem' }}>
                  <label>Week Off</label>
                  <input
                    type="radio"
                    name="status"
                    value="Week Off"
                    checked={selectedStatus === 'Week Off'}
                    onChange={handleStatusChange}
                    style={{ transform: 'scale(1.6)' }}
                  />
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdateStatus}
            color="primary"
            disabled={loading}
            style={{
              padding: '5px 10px',
              backgroundColor: '#6174DD',
              borderRadius: '5px',
              color: 'white',
            }}
          >
            Update
          </Button>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Drawer
        anchor="right"
        open={isAppointmentDrawerOpen}
        onClose={() => {
          setIsAppointmentDrawerOpen(false);
        }}
        PaperProps={{
          sx: {
            width: '280px',
            boxSizing: 'border-box',
          },
        }}
      >
        {loading ? (
          <ShowLoader />
        ) : (
          <div style={{ width: '100%' }}>
            <DialogTitle>Appointment Details</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {appointments.length > 0 ? (
                  appointments.map((appointment, index) => (
                    <div key={index}>
                      <div>Clinet Name: {appointment.clientName}</div>
                      <div>Date: {appointment.dateOfAppointment}</div>
                      <div>Service: {appointment.serviceSelected.service.serviceName}</div>
                      {/* Display other appointment data fields as needed */}
                      {index < appointments.length - 1 && <hr />}{' '}
                      {/* Add a horizontal line between appointments */}
                    </div>
                  ))
                ) : (
                  <div>No appointments selected.</div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsAppointmentDrawerOpen(false);
                }}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Drawer>
    </Box>
  );
};

export default TableViewFormate;

