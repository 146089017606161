//pagination corrected
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ServiceTypeFieldData from './ServiceTypeFieldData';
import DiscountTypeFieldData from './DiscountTypeFieldData';
import SearchImg from '../../assets/search.svg';
import AllPackages from './OurPackages/AllPackages';
import { useLocation } from 'react-router';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const AllMemberships = () => {
  const [data, setData] = useState([]);
  const [membershipType, setMembershipType] = useState('serviceType'); // Default membership type
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [packageFilterData, setPackageFilterData] = useState([]);
  const { fetchAllMemberships, activeBranchId, fetchAllPackages } = useMain();

  const { state } = useLocation();

  // Fetch data based on membership type
  const fetchData = async (type) => {
    let queryParams = `?membershipType=${type}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;

    try {
      setShowLoader(true);
      const res = await fetchAllMemberships(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch package data
  const fetchPackageData = async () => {
    try {
      setShowLoader(true);
      const res = await fetchAllPackages(activeBranchId);
      if (res.statusCode === 200) {
        setPackageData(res?.data || []);
      } else {
        setPackageData([]);
      }
    } catch (error) {
      setPackageData([]);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      fetchPackageData();
      fetchData('serviceType');
    }
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [data, limit, page, search, membershipType]);

  // Handle membership type change
  const handleMembershipTypeChange = (event, newMembershipType) => {
    if (newMembershipType) {
      setMembershipType(newMembershipType);
      setSearch('');
      setLimit(10);
      setPage(1);
      if (newMembershipType === 'packageType') {
        fetchPackageData();
      } else {
        fetchData(newMembershipType);
      }
    }
  };

  // Clear data
  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    fetchData(membershipType); // Re-fetch data based on current membership type
  };

  const applyFilters = () => {
    let filteredData = membershipType === 'packageType'
      ? packageData.filter(item => item.packageName.toLowerCase().includes(search.toLowerCase()))
      : data.filter(item => item.membershipName.toLowerCase().includes(search.toLowerCase()));

    // Set the filtered data
    if (membershipType === 'packageType') {
      setPackageFilterData(filteredData);
    } else {
      setFilterData(filteredData);
    }

    // If the current page exceeds total pages, reset to page 1
    const totalFilteredItems = filteredData.length;
    const totalPages = Math.ceil(totalFilteredItems / limit);
    if (page > totalPages) {
      setPage(1);
    }
  };

  // Calculate total items based on the current membership type
  const totalItems = membershipType === 'packageType' ? packageFilterData.length : filterData.length;
  const totalPages = Math.ceil(totalItems / limit);

  // Data to display based on pagination
  const displayedData = membershipType === 'packageType'
    ? packageFilterData.slice((page - 1) * limit, page * limit)
    : filterData.slice((page - 1) * limit, page * limit);

  console.log(data, "dataMEMBERSHIP")
  return (
    <PageContainer title="Manage Membership" description="This contains all products">
      <DashboardCard2 title="Manage Membership">
        <div style={{ display: 'grid', gridTemplateColumns: '2fr auto' }}>
          <div style={{ marginBottom: '1.7rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{ borderRadius: '7px', border: '0.5px solid #6174DD', padding: '4px 12px', background: '#FFF', height: '40px', width: '10%' }}
              value={limit}
              onChange={(e) => { setLimit(e.target.value); setPage(1); }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center', width: '76%' }} onClick={() => document.getElementById('search-input').focus()}>
            <div style={{ marginBottom: '1rem' }}>
              <InputLabel>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                <img src={SearchImg} alt="" />
                <input
                  id="search-input"
                  type="text"
                  placeholder=""
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                  style={{ marginLeft: '0.5rem' }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      color: '#000',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>


          <div style={{ width: '51%', marginLeft: '2.5rem' }}>
            {/* <ToggleButtonGroup
              value={membershipType}
              exclusive
              onChange={handleMembershipTypeChange}
              sx={{
                borderRadius: '30px',
                height: '40px',
                backgroundColor: '#f0f0f0',
                width: '100%',
                border: '1px solid #cfcece',
                marginLeft: "16rem",
              }}
            >
              <ToggleButton
                value="serviceType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                    '& + div': {
                      display: 'none', // Hide right border of the selected button
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Service Membership
              </ToggleButton>

              <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} />

              <ToggleButton
                value="discountType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                    '&:first-of-type + div': {
                      display: 'none', // Hide left border of the selected button
                    },
                    '&:not(:last-of-type) + div': {
                      display: 'none', // Hide left border for selected button
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Discount Membership
              </ToggleButton>

              <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} />

              <ToggleButton
                value="packageType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                    '&:not(:last-of-type) + div': {
                      display: 'none', // Hide right border for selected but not last
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                All Packages
              </ToggleButton>
            </ToggleButtonGroup> */}
            <ToggleButtonGroup
              value={membershipType}
              exclusive
              onChange={handleMembershipTypeChange}
              sx={{
                borderRadius: '30px',
                height: '40px',
                backgroundColor: '#f0f0f0',
                width: '100%',
                border: '1px solid #cfcece',
                marginLeft: "16rem",
              }}
            >
              <ToggleButton
                value="serviceType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Service Membership
              </ToggleButton>
              {membershipType !== "serviceType" && membershipType !== "discountType" && (
                <div style={{ height: '39px', width: '3px', backgroundColor: '#cfcece' }} />
              )}

              <ToggleButton
                value="discountType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Discount Membership
              </ToggleButton>
              {membershipType !== "discountType" && membershipType !== "packageType" && (
                <div style={{ height: '39px', width: '3px', backgroundColor: '#cfcece' }} />
              )}

              <ToggleButton
                value="packageType"
                sx={{
                  flex: 1,
                  color: '#000',
                  backgroundColor: '#fafafa',
                  borderRadius: '30px',
                  border: 'none',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                All Packages
              </ToggleButton>
            </ToggleButtonGroup>

          </div>
        </div>

        {showLoader ? (
          <ShowLoader />
        ) : membershipType === 'serviceType' ? (
          // filterData.length > 0 ? (
          displayedData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <ServiceTypeFieldData data={displayedData} setData={setData} getData={() => fetchData('serviceType')} state={state} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : membershipType === 'discountType' ? (
          displayedData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <DiscountTypeFieldData data={displayedData} setData={setData} getData={() => fetchData('discountType')} state={state} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : membershipType === 'packageType' ? (
          displayedData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <AllPackages data={packageData} setPackageData={setPackageData} getPackageData={fetchPackageData} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : null}

        {totalItems > 0 && (
          <Grid container justifyContent="end">
            <Pagination
              count={totalPages}
              color="primary"
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </Grid>
        )}
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllMemberships;
