import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AllProducts from 'src/views/products/AllProducts';
import AddProduct from 'src/views/products/AddProduct';
import AllBanners from 'src/views/Banners/AllBanners';
import AllCoupons from 'src/views/Coupons/AllCoupons';
import AddCoupon from 'src/views/Coupons/AddCoupon';
import AllUsers from 'src/views/Users/AllUsers';
import AllOrders from 'src/views/Orders/AllOrders';
import AddBillings from 'src/views/Billings/AddBill';
import BillAnalytics from 'src/views/Billings/analytics/Analytics';
import AddAppointment from 'src/views/Appointments/AddAppointment';
import AddStaff from 'src/views/staffs/AddStaff';
import AddService from 'src/views/Services/AddService';
import AllServices from 'src/views/Services/AllServices';
import AddClient from 'src/views/clients/AddClient';
import AddMembership from 'src/views/Membership/AddMembership';
import AllMembership from 'src/views/Membership/AllMemberships';
import MembershipDetail from 'src/views/Membership/MembershipDetail';
import AllAppointments from 'src/views/Appointments/AllAppointments';
import AllClients from 'src/views/clients/AllClients';
import ClientAnalytics from 'src/views/clients/Analytics/ClientAnalytics';
import ClientDetails from 'src/views/clients/ClientDetails/ClientDetails';
import ClientLead from 'src/views/ClientLead/ClientLead';
import ClientLeads from 'src/views/ClientLead/ClientLeads';
import AllStaffs from 'src/views/staffs/AllStaffs';
import AllBillings from 'src/views/Billings/AllBillings';

import PrintBillPdf from 'src/views/Billings/pdf/BillPdf';
import AllBranch from 'src/views/Branch/AllBranch';
import AddBranch from 'src/views/Branch/AddBranch';
import AllFeedbacks from 'src/views/Feedbacks/AllFeedbacks';
import AddFeedback from 'src/views/Feedbacks/AddFeedback';
import Attendance from 'src/views/staffs/Attendance';
import AllCategories from 'src/views/Category/AllCategories';
import AddCategory from 'src/views/Category/AddCategory';
import AllRequests from 'src/views/Requests/AllRequests';
import AllSubscription from 'src/views/Subscription/AllSubscription';
import AllExpenses from 'src/views/Expenses/AllExpenses';
import AddExpenses from 'src/views/Expenses/AddExpenses';
import ExpenseAnalyticss from 'src/views/Expenses/ExpenseAnalytics/ExpenseAnalytics';
import ForgotPassword from 'src/views/authentication/ForgotPassword';
import AppointmentDetails from 'src/views/Appointments/AppointmentDetails/AppointmentDetails';
import Settings from 'src/views/Settings/Settings';
import AppointmentAnalytics from 'src/views/Appointments/AppointmentAnalytics/AppointmentAnalytics';
import AddEnquiry from 'src/views/Enquiry/AddEnquiry';
import AllEnquiry from 'src/views/Enquiry/AllEnquiry';
import BranchDetails from 'src/views/Branch/BranchDetails';
import PackagePage from 'src/views/Membership/OurPackages/PackagePage';
import PackageDetails from 'src/views/Membership/OurPackages/PackageDetails';
import PackageAllBill from 'src/views/Billings/PackageAllBill';
import AllPackages from 'src/views/Membership/OurPackages/AllPackages';
import WorkAnalysis from 'src/views/staffs/WorkAnalysis';
import ClientReviewPage from 'src/views/Enquiry/ClientReviewPage';
import AllDraftBills from 'src/views/Settings/AllDraftBills';
import PackageBillPdf from 'src/views/Billings/pdf/PackageBillPdf';
import SalerySlip from 'src/views/staffs/SalerySlip';
import Payslip from 'src/views/staffs/PaySlip';
import { exact } from 'prop-types';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const SamplePage = Loadable(lazy(() => import('../views/sample-page/SamplePage')));
// const Icons = Loadable(lazy(() => import('../views/icons/Icons')));
const TypographyPage = Loadable(lazy(() => import('../views/utilities/TypographyPage')));
const Shadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/appointments', exact: true, element: <AllAppointments /> },
      { path: '/addappointment', exact: true, element: <AddAppointment /> },
      { path: '/appointment/analytics', exact: true, element: <AppointmentAnalytics /> },
      { path: '/appointments-history/:id', exact: true, element: <AppointmentDetails /> },
      { path: '/feedbacks', exact: true, element: <AllFeedbacks /> },
      { path: '/addfeedback', exact: true, element: <AddFeedback /> },
      { path: '/requests', exact: true, element: <AllRequests /> },
      { path: '/branches', exact: true, element: <AllBranch /> },
      { path: '/clientReview', exact: true, element: <ClientReviewPage/> },
      { path: '/addbranch', exact: true, element: <AddBranch /> },
      { path: '/branch/:id', exact: true, element: <BranchDetails /> },
      { path: '/billings', exact: true, element: <AllBillings /> },
      { path: '/PackageAllBill', exact: true, element: <PackageAllBill /> },
      { path: '/WorkAnalysis', exact: true, element: <WorkAnalysis /> },
      { path: '/addbilling', exact: true, element: <AddBillings /> },
      { path: '/billing/:billId', exact: true, element: <PrintBillPdf /> },
      { path: '/Packagebilling/:billId', exact: true, element: <PackageBillPdf /> },
      { path: '/bill/analytics', exact: true, element: <BillAnalytics /> },
      { path: '/coupon', exact: true, element: <AllCoupons /> },
      { path: '/addcoupon', exact: true, element: <AddCoupon /> },
      { path: '/expenses', exact: true, element: <AllExpenses /> },
      { path: '/addexpenses', exact: true, element: <AddExpenses /> },
      { path: '/expense/analytics', exact: true, element: <ExpenseAnalyticss /> },
      { path: '/staffs', exact: true, element: <AllStaffs /> },
      { path: '/addstaff', exact: true, element: <AddStaff /> },
      { path: '/salary-page', exact: true, element: <SalerySlip/> },
      { path: '/payslip', exact: true, element: <Payslip/> },
      { path: '/services', exact: true, element: <AllServices /> },
      { path: '/addservice', exact: true, element: <AddService /> },
      { path: '/clients', exact: true, element: <AllClients /> },
      { path: '/client/analytics', exact: true, element: <ClientAnalytics /> },
      { path: '/addclient', exact: true, element: <AddClient /> },
      { path: '/client/:clientId', exact: true, element: <ClientDetails /> },
      { path: '/memberships', exact: true, element: <AllMembership /> },
      { path: '/addmembership', exact: true, element: <AddMembership /> },
      { path: '/membershipdetail/:membershipId', exact: true, element: <MembershipDetail /> },
      { path: '/packagedetail/:packageId', exact: true, element: <PackageDetails /> },
      { path: '/categories', exact: true, element: <AllCategories /> },
      { path: '/addcategory', exact: true, element: <AddCategory /> },
      { path: '/sample-page', exact: true, element: <SamplePage /> },
      // { path: '/icons', exact: true, element: <Icons /> },
      { path: '/products', exact: true, element: <AllProducts /> },
      { path: '/addproduct', exact: true, element: <AddProduct /> },
      { path: '/banners', exact: true, element: <AllBanners /> },
      { path: '/orders', exact: true, element: <AllOrders /> },
      { path: '/users', exact: true, element: <AllUsers /> },
      { path: '/attendance', exact: true, element: <Attendance /> },
      { path: '/ui/typography', exact: true, element: <TypographyPage /> },
      { path: '/ui/shadow', exact: true, element: <Shadow /> },
      { path: '/subscription', exact: true, element: <AllSubscription /> },
      { path: '/ClientLead', exact: true, element: <ClientLead /> },
      { path: '/ClientLeads', exact: true, element: <ClientLeads /> },
      { path: '/addenquiry', exact: true, element: <AddEnquiry /> },
      { path: '/enquiries', exact: true, element: <AllEnquiry /> },
      { path: '/settings', exact: true, element: <Settings /> },
      { path: '/PackagePage', exact: true, element: <PackagePage/> },
      { path: '/AllDraftBills', exact: true, element: <AllDraftBills/> },
      // { path: '/allPackage', exact: true, element: <AllPackages/> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/forgotpassword', element: <ForgotPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
