import { DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IconButton } from 'rsuite';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const SalarySlip = () => {
    const [data, setData] = useState(null); // Expecting an object
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const staffId = queryParams.get('staffId');

    const { fetchSalarySlipById, activeBranchId } = useMain();
    const navigate = useNavigate();

    const handleDetails = (salary) => {
        navigate('/payslip', { state: { staff: data, salarySlip: salary } });
    };

    const getData = async (staffId) => {
        try {
            setLoading(true);
            const res = await fetchSalarySlipById(staffId, activeBranchId);
            if (res.statusCode === 200) {
                setData(res.data || {}); // Store the object directly
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (staffId && activeBranchId) {
            getData(staffId);
        }
        return () => {
            setData(null);
        };
    }, [staffId, activeBranchId]);
console.log(data,'data')
    return (
        <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' }, padding: '20px' }}>
            {loading ? (
                <ShowLoader />
            ) : data ? (
                <>
                    {/* Staff Details */}
                    <div className="staff-details" style={{ marginBottom: '10px' }}>
                        <h3><strong>Staff Name : </strong>{data.name}</h3>
                        <p><strong>Date of Joining:</strong> {data.dateOfJoining ? data.dateOfJoining.split('-').reverse().join('-') : ''}</p>
                        <p><strong>Department:</strong> {data.department}</p>
                    </div>

                    {/* Salary Table */}
                    <table className="table-container mt-3">
                        <thead className="table-thead">
                            <tr>
                                {['Pay Month', 'Monthly Present', 'Monthly Absent', 'Monthly Half Day', 'Action'].map((h, index) => (
                                    <th key={index} style={{ width: '100%' }}>{h}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="table-tbody">
                            {data.salarySlip?.map((salary, index) => (
                                <tr key={index}>
                                    <td style={{ width: '100%' }}>{salary.paymentMonth ?? '-'}</td>
                                    <td style={{ width: '100%' }}>{salary.monthlyPresent ?? '-'}</td>
                                    <td style={{ width: '100%' }}>{salary.monthlyAbsent ?? '-'}</td>
                                    <td style={{ width: '100%' }}>{salary.monthlyHalfDay ?? '-'}</td>
                                    <td style={{ width: '116%' }}>
                                        <IconButton onClick={() => handleDetails(salary)} color="primary" title="View Details">
                                            <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem' }} />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No data available</p>
            )}
        </Box>
    );
};

export default SalarySlip;
