// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const ProductSuggestion = ({ data, handleProductSuggestedClick }) => {
//   const navigate = useNavigate();
//   const [hoveredSuggestion, setHoveredSuggestion] = useState(null);

//   const handleClick = (product) => {
//     if (product.productCount === 0) {
//       alert("Your Product count is zero. Please update the product count."); // Show popup message
//       navigate('/products'); // Navigate to the products page
//     } else {
//       handleProductSuggestedClick(product);
//     }
//   };

//   const ulStyle = {
//     listStyle: "none",
//     padding: 0,
//     margin: 0,
//     position: "absolute",
//     backgroundColor: "#fff",
//     border: "1px solid #ccc",
//     zIndex: 1000,
//     maxHeight: "300px",
//     overflowY: "auto",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//     width: "330px",
//   };

//   const liStyle = {
//     padding: "8px",
//     cursor: "pointer",
//   };

//   // CSS for customizing scrollbar in a React component
//   const scrollbarStyle = `
//     &::-webkit-scrollbar {
//       width: 5px;
//     }
//     &::-webkit-scrollbar-track {
//       background: #f1f1f1;
//     }
//     &::-webkit-scrollbar-thumb {
//       background: #888;
//     }
//     &::-webkit-scrollbar-thumb:hover {
//       background: #555;
//     }
//   `;

//   return (
//     <ul style={ulStyle} className="custom-scrollbar">
//       {data.map((product, index) => (
//         <li
//           key={index}
//           onClick={() => handleClick(product)}
//           style={{
//             ...liStyle,
//             ...(hoveredSuggestion === index ? { backgroundColor: '#6174DD', color: 'white' } : '')
//           }}
//           onMouseEnter={() => setHoveredSuggestion(index)}
//           onMouseLeave={() => setHoveredSuggestion(null)}
//         >
//           <span>{product?.productName}</span>
//           <span> (Count: {product.productCount})</span>
//         </li>
//       ))}
//       <style>
//         {`
//           .custom-scrollbar {
//             ${scrollbarStyle}
//           }
//         `}
//       </style>
//     </ul>
//   );
// };

// export default ProductSuggestion;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductSuggestion = ({ data, handleProductSuggestedClick }) => {
  const navigate = useNavigate();
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);

  const handleClick = (product) => {
    if (product.productCount === 0) {
      alert("Your Product count is zero. Please update the product count."); // Show popup message
      navigate('/products'); // Navigate to the products page
    } else {
      handleProductSuggestedClick(product);
    }
  };

  const ulStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "absolute",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    zIndex: 1000,
    maxHeight: "300px",
    overflowY: "auto",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "250px", // Reduced width
  };

  const liStyle = {
    padding: "8px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
  };
console.log(data,'ppppppppp')
  return (
    <ul style={ulStyle}>
      {data.map((product, index) => (
        <li
          key={index}
          onClick={() => handleClick(product)}
          style={{
            ...liStyle,
            ...(hoveredSuggestion === index ? { backgroundColor: '#6174DD', color: 'white' } : {})
          }}
          onMouseEnter={() => setHoveredSuggestion(index)}
          onMouseLeave={() => setHoveredSuggestion(null)}
        >
          <span>{product?.productName} - ₹{product?.sellPrice}</span>
          <span>(Count: {product.productCount})</span>
        </li>
      ))}
    </ul>
  );
};

export default ProductSuggestion;
