
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Button, Avatar } from '@mui/material';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import PageContainer from 'src/components/container/PageContainer';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import numWords from 'num-words';
import { useMain } from 'src/views/hooks/useMain';
import './PackageBillPdf.css';

const PackageBillPdf = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();
    const { billId } = useParams();
    const { fetchPackageBillById, setShowMessage, activeBranchId } = useMain();

    const printBillHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${billId}`,
        onAfterPrint: () =>
            setShowMessage({ message: 'Bill downloaded successfully', messageType: 'success' }),
        onPrintError: () =>
            setShowMessage({ message: 'Something went wrong while printing the bill', messageType: 'error' }),
    });

    const downloadPdfHandler = async () => {
        const input = componentRef.current;
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${billId || 'bill'}.pdf`);
    };

    const getData = async (billId) => {
        try {
            const res = await fetchPackageBillById(billId, activeBranchId);
            if (res.statusCode === 200) {
                setData(res.data || {});
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (billId && activeBranchId) {
            getData(billId);
        }
        return () => {
            setData({});
        };
    }, [billId, activeBranchId]);

    const capitalizeWords = (inputString) => {
        return inputString
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    
    return (
      <PageContainer title={`${data?.billType === 'Package' ? 'Package Invoice Bill' : 'Membership Invoice Bill'}`}>
        <DashboardCard2 title={`${data?.billType === 'Package' ? 'Package Invoice Bill' : 'Membership Invoice Bill'}`}>
          {loading ? (
            <ShowLoader />
          ) : Object.keys(data).length === 0 ? (
            <div className="no-data-message">No bill record found</div>
          ) : (
            <div>
              <div ref={componentRef} className="Package-bill-container">
                <div className="printPackage-header">
                  {data?.branchDetails?.logo && (
                    <Avatar
                      src={data?.branchDetails?.logo?.url}
                      alt="Packagebranch-logo"
                      className="Packagebranch-logo"
                    />
                  )}
                  <div className="header-package-text">
                    <h6>{data?.branchDetails?.branchName}</h6>
                    <span>Email: {data?.branchDetails?.branchEmail}</span>
                  </div>
                </div>

                <div className="Package-bill-details">
                  <div className="Package-client-info">
                    <h6>Bill To:</h6>
                    <p>Client Name: {data?.clientName}</p>
                    <p>Contact No.: {data?.clientNumber}</p>
                    <p>
                      {data?.billType === "Package" ? 'Package' : "Membership"} Validity:
                      {data?.endDate || data?.servicesPackage?.validTill || 'Expired'}
                    </p>
                  </div>
                  <div className="invoice-info">
                    <h6>Invoice No.: {data?.invoice}</h6>
                    <h6>Date: {data?.dateOfBilling}</h6>
                  </div>
                </div>

                <table className="Package-print-table">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      {(data.billType === 'Package' && <th>Package</th>) ||
                        (data.billType === 'Membership' && <th>Membership</th>)}
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {data?.servicesPackage?.packageName ||
                          data?.membershipDetails?.membershipName}
                      </td>
                      <td>
                        {data?.servicesPackage?.price || data?.membershipDetails?.membershipPrice}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <div className="additional-services">
                          {data.billType === 'Package' && data.servicesPackage?.services.length > 0 && (
                            <h6>Package Services</h6>
                          )}
                          {data.billType === 'Membership' &&
                            data.membershipDetails?.servicesOffered.length > 0 && <h6>Membership Services</h6>}
                          {data?.servicesPackage?.services.length > 0 && (
                            <ul>
                              {data?.servicesPackage?.services.map((service, index) => (
                                <li key={index}>
                                  {index + 1}. {service.serviceName} (Count = 1)
                                </li>
                              ))}
                                </ul>
                                
                          )}
                          {data?.membershipDetails?.membershipType === 'serviceType' && (
                            <ul>
                              {data?.membershipDetails?.servicesOffered.map(
                                (serviceOffered, index) => (
                                  <li key={index}>
                                    {index + 1}. {serviceOffered.service.serviceName} (Count ={' '}
                                    {serviceOffered.availableCount})
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                          {data?.membershipDetails?.membershipType === 'discountType' &&
                            (data.membershipDetails?.discount?.discountOnService ||
                              data.membershipDetails?.discount?.discountOnProduct) && (
                              <ul>
                                {data.membershipDetails?.discount?.discountOnService && (
                                  <li>
                                    Discount on Services:{' '}
                                    {data.membershipDetails?.discount.discountOnService}{' '}
                                    {data.membershipDetails?.discount.serviceDiscountType}
                                  </li>
                                )}
                                {data.membershipDetails?.discount?.discountOnProduct && (
                                  <li>
                                    Discount on Product:{' '}
                                    {data.membershipDetails?.discount.discountOnProduct}{' '}
                                    {data.membershipDetails?.discount.productDiscountType}
                                  </li>
                                )}
                              </ul>
                            )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="Package-amount-summary">
                  <h6>Amount In Words:</h6>
                  <p>{capitalizeWords(numWords(data.amountPayable))} Rupees Only</p>
                </div>
              </div>

              <div className="Package-action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadPdfHandler}
                  className="Package-download-button"
                >
                  Download PDF
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={printBillHandler}
                  className="Package-print-button"
                >
                  Print Bill
                </Button>
              </div>
            </div>
          )}
        </DashboardCard2>
      </PageContainer>
    );
};

export default PackageBillPdf;
