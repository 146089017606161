import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { Button } from 'reactstrap';
import { useMain } from 'src/views/hooks/useMain';
import { Box } from '@mui/system';
import TextInputs from 'src/components/inputs/TextInputs';

const MaskingSettings = ({ data, refreshData }) => {
  const { updateOwnerCombinedMasking, setShowMessage } = useMain();

  const [isMasked, setIsMasked] = useState(data?.isMasked || false);
  const [isSalesMasked, setIsSalesMasked] = useState(data?.isSalesMasked || false);
  const [password, setPassword] = useState('');
  const [openDialogue, setOpenDialogue] = useState(false);

  const handleToggleChange = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleMaskToggle = async () => {
    if (password === '') {
      setShowMessage({ message: 'Please enter login password', messageType: 'error' });
      return;
    }
    try {
      const updatedSettings = {
        isMasked: !isMasked,
        isSalesMasked: !isSalesMasked,
        password,
      };
      const res = await updateOwnerCombinedMasking(data._id, updatedSettings);
  
      if (res.statusCode === 200) {
        setShowMessage({ message: res.message || 'Settings updated successfully', messageType: 'success' });
  
        // 🔹 UI update ke liye state update
        setIsMasked(updatedSettings.isMasked);
        setIsSalesMasked(updatedSettings.isSalesMasked);
        setPassword('');
        setOpenDialogue(false);
  
        // 🔹 Refresh the latest data and force a re-render
        await refreshData(data._id);
      } else {
        setShowMessage({ message: res.message || 'Update failed', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    }
  };
  

  return (
    <div style={{ padding: '20px', backgroundColor: '#FAFAFA', borderRadius: '10px', border: '0.5px solid #CFCFCF' }}>
      <FormControlLabel
        control={<Switch checked={isMasked && isSalesMasked} onChange={handleToggleChange} />}
        label="Mask All Information (General & Sales)"
      />
      
      <Dialog open={openDialogue} onClose={handleCloseDialogue}>
        <DialogTitle>Confirm Masking Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to turn masking {isMasked && isSalesMasked ? 'OFF' : 'ON'} for both General and Sales Information?
          </DialogContentText>
          <Box style={{ marginTop: '10px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInputs
                  title={'Login Password'}
                  name={'password'}
                  value={password}
                  handleChange={(e) => setPassword(e.target.value)}
                  type={'password'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogue}>Cancel</Button>
          <Button onClick={handleMaskToggle} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MaskingSettings;
