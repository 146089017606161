// import React, { useState } from 'react';

// const ServiceSuggestion = ({ data, handleServiceSuggestedClick }) => {
//   const [hoveredSuggestion, setHoveredSuggestion] = useState(null);

//   const handleChange = (service, e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     handleServiceSuggestedClick(service);
//   };

//   const ulStyle = {
//     listStyle: "none",
//     padding: 0,
//     margin: 0,
//     position: "absolute",
//     backgroundColor: "#fff",
//     border: "1px solid #ccc",
//     zIndex: 1000,
//     maxHeight: "300px",
//     overflowY: "auto",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//     width: "330px",
//   };

//   const liStyle = {
//     padding: "8px",
//     cursor: "pointer",
//   };

//   // CSS for customizing scrollbar in a React component
//   const scrollbarStyle = `
//     &::-webkit-scrollbar {
//       width: 5px;
//     }
//     &::-webkit-scrollbar-track {
//       background: #f1f1f1;
//     }
//     &::-webkit-scrollbar-thumb {
//       background: #888;
//     }
//     &::-webkit-scrollbar-thumb:hover {
//       background: #555;
//     }
//   `;

//   return (
//     <ul style={ulStyle} className="custom-scrollbar">
//       {data.map((service, index) => (
//         <li
//           key={index}
//           onClick={(e) => handleChange(service, e)}
//           style={{
//             ...liStyle,
//             ...(hoveredSuggestion === index ? { backgroundColor: '#6174DD', color: 'white' } : '')      
//           }}
//           onMouseEnter={() => setHoveredSuggestion(index)}
//           onMouseLeave={() => setHoveredSuggestion(null)}
//         >
//           {service?.serviceName}
//         </li>
//       ))}
//       <style>
//         {`
//           .custom-scrollbar {
//             ${scrollbarStyle}
//           }
//         `}
//       </style>
//     </ul>
//   );
// };

// export default ServiceSuggestion;


import React, { useState } from "react";

const ServiceSuggestion = ({ data, handleServiceSuggestedClick }) => {
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);

  const handleChange = (service, e) => {
    e.preventDefault();
    e.stopPropagation();
    handleServiceSuggestedClick(service);
  };

  // Group services by category
  const groupedServices = data.reduce((acc, service) => {
    if (!acc[service.category]) {
      acc[service.category] = [];
    }
    acc[service.category].push(service);
    return acc;
  }, {});

  const containerStyle = {
    position: "absolute",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    zIndex: 1000,
    maxHeight: "300px",
    overflowY: "auto",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "250px",
    padding: "10px",
    borderRadius: "5px",
  };

  const categoryStyle = {
    fontWeight: "bold",
    padding: "8px 5px",
    backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #ddd",
  };

  const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
    cursor: "pointer",
    borderBottom: "1px solid #eee",
  };

  return (
    <div style={containerStyle}>
      {Object.entries(groupedServices).map(([category, services]) => (
        <div key={category}>
          <div style={categoryStyle}>{category}</div>
          {services.map((service, index) => (
            <div
              key={service._id}
              onClick={(e) => handleChange(service, e)}
              style={{
                ...itemStyle,
                backgroundColor: hoveredSuggestion === service._id ? "#6174DD" : "white",
                color: hoveredSuggestion === service._id ? "white" : "black",
              }}
              onMouseEnter={() => setHoveredSuggestion(service._id)}
              onMouseLeave={() => setHoveredSuggestion(null)}
            >
              <span>{service.serviceName}</span>
              <span>₹{service.price}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ServiceSuggestion;

